<template>
  <div>
    <div class="mb-2">
      <small>Enter date</small>
      <Editor
        v-model="formulaInfo.data.value"
        :input="true"
        :formula="true"
      />
    </div>
    <BaseSelect
      v-model="formulaInfo.data.timezone"
      size="is-small"
      class="mb-2"
      label="Select Timezone"
      :label-props="{
        customClass: 'normal'
      }"
    >
      <option
        v-for="(tzName, index) in timezones"
        :key="'tZone' + index"
      >
        {{ tzName }}
      </option>
    </BaseSelect>
    <BaseInput
      v-model="formulaInfo.data.year"
      label="Set Year"
      :label-props="{
        customClass: 'normal'
      }"
      class="mb-2"
    />
    <BaseInput
      v-model="formulaInfo.data.month"
      label="Set Month"
      :label-props="{
        customClass: 'normal'
      }"
      class="mb-2"
    />
    <BaseInput
      v-model="formulaInfo.data.date"
      label="Set Date"
      :label-props="{
        customClass: 'normal'
      }"
      class="mb-2"
    />
    <BaseInput
      v-model="formulaInfo.data.hour"
      label="Set Hour"
      :label-props="{
        customClass: 'normal'
      }"
      class="mb-2"
    />
    <BaseInput
      v-model="formulaInfo.data.minute"
      label="Set Minute"
      :label-props="{
        customClass: 'normal'
      }"
      class="mb-2"
    />
    <BaseInput
      v-model="formulaInfo.data.second"
      label="Set Second"
      :label-props="{
        customClass: 'normal'
      }"
      class="mb-2"
    />
  </div>
</template>

<script >
// libs
import { defineAsyncComponent, nextTick, onMounted, ref } from '@vue/composition-api';
import moment from 'moment-timezone';
import { debouncedWatch } from '@vueuse/core';
import lodash from 'lodash'; // components

import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
const Editor = defineAsyncComponent(() => import('@/modules/core/components/wysiwyg/Editor'));
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const formulaInfo = ref({
    formula: 'MANIPULATE DATE',
    data: {
      value: '',
      year: '',
      month: '',
      date: '',
      hour: '',
      minute: '',
      second: '',
      timezone: ''
    }
  });
  const timezones = ref([]);
  let avoidSavingFormula = false;
  onMounted(async () => {
    await nextTick();
    timezones.value = moment.tz.names();

    if (Object.keys(props.value?.data || {}).length) {
      avoidSavingFormula = true;
      formulaInfo.value = lodash.cloneDeep(props.value);
    }
  });
  debouncedWatch(() => formulaInfo.value, () => {
    if (avoidSavingFormula) {
      avoidSavingFormula = false;
    } else {
      emit('input', formulaInfo.value);
    }
  }, {
    deep: true,
    debounce: 500
  });
  return {
    formulaInfo,
    timezones
  };
};

__sfc_main.components = Object.assign({
  Editor,
  BaseSelect,
  BaseInput
}, __sfc_main.components);
export default __sfc_main;
</script>
