<template>
  <BaseModal
    v-model="isDeployModalActive"
    :has-modal-card="true"
    :trap-focus="true"
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-label="Deploy"
    aria-modal
  >
    <CardPopup
      title="Deployment"
      class="w-800"
      :show-footer="false"
      @hide="isDeployModalActive = false"
    >
      <template #body>
        <div class="card-content">
          <div
            v-for="(environment, index) in moduleDeployStore.environments"
            :key="index"
            class="mb-5 box"
          >
            <h1 class="size-4 mb-2">
              Status for the <strong>{{ environment.name }}</strong>
            </h1>
            <div
              v-if="environment.tag"
              class="mb-3"
            >
              <b-message
                type="is-success"
                size="is-small"
              >
                Version deployed and running on the {{ environment.name }} is <strong>{{ environment.tag }}</strong>
              </b-message>

              <b-field>
                <b-input
                  v-model="environment.deployed_url"
                  size="is-small"
                  expanded
                  :disabled="true"
                />
                <p class="control">
                  <b-button
                    type="is-light"
                    size="is-small"
                    icon-right="content-copy"
                    @click="copyUrl(environment.deployed_url)"
                  />
                </p>
                <p class="control">
                  <b-button
                    size="is-small"
                    type="is-primary"
                    label="Go to the URL"
                    @click="goToExternalLink(environment.deployed_url)"
                  />
                </p>
              </b-field>
            </div>
            <b-message
              v-else
              type="is-danger"
              size="is-small"
            >
              There is no version deployed on the
              {{ environment.name }}
            </b-message>

            <b-button
              type="is-primary"
              expanded
              :icon-right="environment.is_protected ? 'lock' : ''"
              @click="openDeploymentModal(environment.id, environment.name, environment.is_protected)"
            >
              New deployment to the {{ environment.name }}
            </b-button>
          </div>
        </div>
      </template>
    </CardPopup>
  </BaseModal>
</template>

<script >
import { computed, defineAsyncComponent } from '@vue/composition-api';
import { fetchDeploymentsService } from '@/services/build-service/deploymentRequests';
import { useModuleStore } from '@/modules/builder/store/moduleStore';
import { useModuleDeployStore } from '@/modules/builder/store/moduleDeployStore';
import { convertModuleVersionUTCToCestTime } from '@/helpers/util';
import { useBuefy } from '@/hooks/buefy'; //-- child components --//

const BaseModal = defineAsyncComponent(() => import('@/modules/core/components/generics/base-modal/BaseModal.vue'));
const CardPopup = defineAsyncComponent(() => import('@/modules/core/components/generics/base-modal/CardPopup.vue')); //-- component props --//

const __sfc_main = {};
__sfc_main.props = {
  moduleTypeId: {
    type: Number,
    default: 0
  },
  value: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props; //-- component events --//

  const emit = __ctx.emit; //-- composable hooks --//

  const moduleStore = useModuleStore();
  const moduleDeployStore = useModuleDeployStore();
  const buefy = useBuefy(); //-- deploy environment logic --//

  const isDeployModalActive = computed({
    get() {
      return props.value;
    },

    set(val) {
      emit('input', val);
    }

  });

  const goToExternalLink = link => {
    window.open(link, '_blank');
  };

  const openDeploymentModal = async (environment_id, environment_name, environment_is_protected) => {
    try {
      const {
        data: {
          data
        }
      } = await fetchDeploymentsService({
        module_id: moduleStore.moduleId,
        environment_id: environment_id
      });
      moduleDeployStore.setEnvironmentDeploymentOptions({
        environment_id: environment_id,
        name: environment_name,
        is_protected: environment_is_protected,
        module_id: moduleStore.moduleId,
        version_id: data.version_id,
        tag: convertModuleVersionUTCToCestTime(data.name),
        deployed_url: moduleDeployStore.deploymentUrls[props.moduleTypeId] ? moduleDeployStore.deploymentUrls[props.moduleTypeId] + data.id : 'NO PUBLIC FACING URL',
        theme: data.theme,
        password_protected: data.password_protected,
        password: data.password,
        is_analytics_active: data.is_analytics_active,
        language: data.language,
        ipAddresses: data.ipAddresses
      });
    } catch (err) {
      console.error(err);
      moduleDeployStore.setEnvironmentDeploymentOptions({
        environment_id: environment_id,
        name: environment_name,
        is_protected: environment_is_protected,
        module_id: moduleStore.moduleId,
        version_id: '-- Select a version --',
        tag: '',
        deployed_url: '',
        theme: 'Default',
        password_protected: false,
        password: '',
        is_analytics_active: true,
        language: 'en',
        ipAddresses: ''
      });
    } finally {
      moduleStore.fetchVersions(moduleStore.moduleId);
      emit('select-deployment', environment_name);
    }
  };

  const copyUrl = async url => {
    try {
      await navigator.clipboard.writeText(url || '');
      buefy.toast.open({
        message: 'Copied URL',
        type: 'is-success'
      });
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  return {
    moduleDeployStore,
    isDeployModalActive,
    goToExternalLink,
    openDeploymentModal,
    copyUrl
  };
};

__sfc_main.components = Object.assign({
  BaseModal,
  CardPopup
}, __sfc_main.components);
export default __sfc_main;
</script>
