<template>
  <div v-if="selectedCellProperties">
    <BaseSelect
      :value="selectedCellProperties.element.type"
      label="Element"
      expanded
      @input="updateCellElement($event)"
    >
      <option
        v-for="(element, eIndex) in elementOptions"
        :key="'element' + eIndex"
        :value="element.type"
      >
        {{ element.label }}
      </option>
    </BaseSelect>
    <BaseLabel>
      Cell Borders:
    </BaseLabel>
    <b-switch
      v-model="selectedCellProperties.border.left"
    >
      Left
    </b-switch>
    <b-switch
      v-model="selectedCellProperties.border.right"
    >
      Right
    </b-switch>
    <b-switch
      v-model="selectedCellProperties.border.top"
    >
      Top
    </b-switch>
    <b-switch
      v-model="selectedCellProperties.border.bottom"
    >
      Bottom
    </b-switch>
    <!-- spanning not needed for now -->
    <!-- <BaseInput
        v-model="selectedCellProperties.rowSpan"
        label="Row Span"
        expanded
      />
      <BaseInput
        v-model="selectedCellProperties.colSpan"
        label="Column Span"
        expanded
      /> -->
  </div>
</template>

<script >
// libs
import { ref } from '@vue/composition-api'; // components

import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
import BaseLabel from '@/modules/core/components/generics/BaseLabel.vue'; // import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
// composables

import { useBasicProperties } from '@/modules/builder/components/form-builder/properties/field-properties/basic-properties/basicProperties';
import { useGrid } from './grid';
import { useFormBuilder } from '@/modules/builder/components/form-builder/formBuilder'; // others

import { formElementList } from '@/modules/builder/components/form-builder/form-elements/formElements';
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const {
    selectedGridCells,
    selectedCellProperties
  } = useGrid();
  const {
    basicProperties
  } = useBasicProperties();
  const {
    selectedField
  } = useFormBuilder();
  const elementOptions = ref(formElementList.filter(element => !['section', 'data-table', 'address', 'tariff-comparison', 'grid', 'document', 'iframe', 'next-btn', 'back-btn'].includes(element.type)));

  const updateCellElement = elementType => {
    const {
      rowIndex,
      columnIndex
    } = selectedGridCells.value[0];
    const newElement = formElementList.find(element => element.type === elementType);
    const cellElement = newElement.factory();
    cellElement.id = `${selectedField.value.id}_cell${rowIndex}${columnIndex}`; // override element ID to keep it constant for a cell, to avoid recreating variables on changing grid

    basicProperties.value.cells.data[rowIndex][columnIndex].properties.basic.element = cellElement;
  };

  return {
    selectedCellProperties,
    elementOptions,
    updateCellElement
  };
};

__sfc_main.components = Object.assign({
  BaseSelect,
  BaseLabel
}, __sfc_main.components);
export default __sfc_main;
</script>
