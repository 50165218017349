<template>
  <div>
    <span class="label my-2">First date:</span>
    <small>Timezone</small>
    <b-select
      v-model="formulaInfo.data.first_date.timezone"
      size="is-small"
      expanded
    >
      <option
        v-for="(tzName, index) in timezones"
        :key="index"
      >
        {{ tzName }}
      </option>
    </b-select>
    <small class="mt-2">Date</small>
    <b-select
      v-model="formulaInfo.data.first_date.type"
      expanded
      size="is-small"
    >
      <option
        v-for="(type, index) in types"
        :key="index"
      >
        {{ type }}
      </option>
    </b-select>
    <div v-if="firstDateUnitLabel">
      <small>{{ firstDateUnitLabel }}</small>
      <editor
        v-model="formulaInfo.data.first_date.units"
        :input="true"
        :formula="true"
      />
      <small>Years from today:</small>
      <editor
        v-model="formulaInfo.data.first_date.units"
        :input="true"
        :formula="true"
      />
    </div>
    <div v-if="formulaInfo.data.first_date.type === 'Custom'">
      <small class="mt-2">Type in a custom date (YYYY-MM-DD hh:mm:ss)</small>
      <editor
        v-model="formulaInfo.data.first_date.date"
        :input="true"
        :formula="true"
      />
    </div>
    <span class="label my-2">Second date:</span>
    <small>Timezone</small>
    <b-select
      v-model="formulaInfo.data.second_date.timezone"
      size="is-small"
      expanded
    >
      <option
        v-for="(tzName, index) in timezones"
        :key="index"
      >
        {{ tzName }}
      </option>
    </b-select>
    <small class="mt-2">Date</small>
    <b-select
      v-model="formulaInfo.data.second_date.type"
      expanded
      size="is-small"
    >
      <option
        v-for="(type, index) in [...types, ...secondDateAdditionalTypes]"
        :key="index"
      >
        {{ type }}
      </option>
    </b-select>
    <div v-if="secondDateUnitLabel">
      <small>{{ secondDateUnitLabel }}</small>
      <editor
        v-model="formulaInfo.data.second_date.units"
        :input="true"
        :formula="true"
      />
    </div>
    <div v-if="formulaInfo.data.second_date.type === 'Custom'">
      <small class="mt-2">Type in a custom date (YYYY-MM-DD hh:mm:ss)</small>
      <editor
        v-model="formulaInfo.data.second_date.date"
        :input="true"
        :formula="true"
      />
    </div>
    <span class="label my-2">Result:</span>
    <b-select
      v-model="formulaInfo.data.result.type"
      expanded
      size="is-small"
    >
      <option
        v-for="(type, index) in results"
        :key="index"
      >
        {{ type }}
      </option>
    </b-select>
    <b-select
      v-if="formulaInfo.data.result.type === 'Date'"
      v-model="formulaInfo.data.result.format"
      class="mt-2"
      size="is-small"
      expanded
    >
      <option
        v-for="(format, index) in formats"
        :key="index"
      >
        {{ format }}
      </option>
    </b-select>
    <b-select
      v-else-if="formulaInfo.data.result.type === 'Number'"
      v-model="formulaInfo.data.result.units"
      class="mt-2"
      size="is-small"
      expanded
    >
      <option
        v-for="(unit, index) in units"
        :key="index"
      >
        {{ unit }}
      </option>
    </b-select>
    <div v-else>
      <small class="mt-2">Type in a custom format <b-tooltip
        type="is-dark"
        multilined
        position="is-top"
        size="is-large"
      >
        <b-icon
          icon="help-circle-outline"
          custom-size="mdi-18px"
        />
        <template #content>
          <div>
            Custom Format allows you to specify how you want your time duration displayed. <br>
            Use placeholders like :-
            <ul>
              <li>'(Y)' for years </li>
              <li>'(M)' for months</li>
              <li>'(D)' for days</li>
              <li>'(h)' for hours</li>
              <li>'(m)' for minutes</li>
              <li>'(s)' for seconds</li>
            </ul>
            For example, '(Y) years and (M) months' will show as '5 years and 3 months' when filled in.
          </div>
        </template>
      </b-tooltip></small>
      <editor
        v-model="formulaInfo.data.result.customFormat"
        :input="true"
        :formula="true"
      />
    </div>
  </div>
</template>

<script >
import { computed, defineAsyncComponent, nextTick, onMounted, ref } from '@vue/composition-api';
import moment from 'moment-timezone';
import { debouncedWatch } from '@vueuse/core';
import lodash from 'lodash';
const Editor = defineAsyncComponent(() => import('@/modules/core/components/wysiwyg/Editor'));
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const formulaInfo = ref({
    formula: 'DATE ARITHMETICS',
    data: {
      first_date: {
        timezone: 'Europe/Berlin',
        type: '',
        date: '',
        units: 0
      },
      second_date: {
        timezone: 'Europe/Berlin',
        type: '',
        date: '',
        units: 0
      },
      result: {
        type: '',
        format: '',
        units: '',
        customFormat: ''
      }
    }
  });
  const types = ref(['Today', 'Yesterday', 'Tomorrow', 'Days from today', 'Days before today', 'Months before today', 'Months from today', 'Years before today', 'Years from today', 'Custom']);
  const secondDateAdditionalTypes = ref(['Days from first date', 'Days before first date', 'Months before first date', 'Months from first date', 'Years before first date', 'Years from first date']);
  const units = ref(['Days', 'Months', 'Years']);
  const results = ref(['Date', 'Number', 'Custom']);
  const formats = ref(['YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD', 'HH:mm:ss', 'HH:mm', 'DD.MM.YYYY']);
  const timezones = ref([]);
  const firstDateUnitLabel = computed(() => getDateUnitLabel(formulaInfo.value.data.first_date.type));
  const secondDateUnitLabel = computed(() => getDateUnitLabel(formulaInfo.value.data.second_date.type));

  const getDateUnitLabel = dateType => {
    return ['Days from today', 'Days before today', 'Months from today', 'Months before today', 'Years from today', 'Years before today', 'Days from first date', 'Days before first date', 'Months from first date', 'Months before first date', 'Years from first date', 'Years before first date'].includes(dateType) ? `${dateType}:` : '';
  };

  let avoidSavingFormula = false;
  onMounted(async () => {
    try {
      await nextTick();
      timezones.value = moment.tz.names();

      if (Object.keys(props.value?.data || {}).length) {
        avoidSavingFormula = true;
        formulaInfo.value = lodash.cloneDeep(props.value);
      }
    } catch (err) {
      console.error(err);
    }
  });
  debouncedWatch(() => formulaInfo.value, () => {
    if (avoidSavingFormula) {
      avoidSavingFormula = false;
    } else {
      emit('input', formulaInfo.value);
    }
  }, {
    deep: true,
    debounce: 500
  });
  return {
    formulaInfo,
    types,
    secondDateAdditionalTypes,
    units,
    results,
    formats,
    timezones,
    firstDateUnitLabel,
    secondDateUnitLabel
  };
};

__sfc_main.components = Object.assign({
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>