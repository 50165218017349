<template>
  <div>
    <BaseInput
      v-model="apiConnectorInfo.name"
      label="Node name"
    />

    <BaseSelect
      v-model="apiConnectorInfo.method"
      placeholder="e.g. POST"
      label="Method"
    >
      <option
        v-for="(method, index) in options.methods"
        :key="index"
      >
        {{ method }}
      </option>
    </BaseSelect>

    <Editor
      v-model="apiConnectorInfo.url"
      :input="true"
      :formula="true"
      label="Base URL"
      style="margin-bottom: 20px"
    />

    <div class="custom-api-tabs">
      <b-tabs v-model="activeTab">
        <b-tab-item label="Headers">
          <div
            class="columns"
            style="margin-bottom: 0px;"
          >
            <div class="column is-5">
              Name
            </div>
            <div class="column is-5">
              Value
            </div>
            <div class="column is-2" />
          </div>
          <div
            v-for="(header, index) in apiConnectorInfo.headers"
            :key="index"
            class="columns"
          >
            <Editor
              v-model="header.name"
              :input="true"
              :formula="true"
              class="column is-5"
            />
            <Editor
              v-model="header.value"
              :input="true"
              :formula="true"
              class="column is-5"
            />
            <div class="column is-2">
              <b-button
                size="is-small"
                type="is-danger"
                icon-right="delete"
                @click="apiConfigManager.removeHeader(index)"
              />
            </div>
          </div>

          <b-button
            type="is-primary"
            class="sidebar-add"
            @click="apiConfigManager.addHeader()"
          >
            <b-icon
              icon="plus"
              size="is-small"
              variant="light"
            />
            Add
          </b-button>
        </b-tab-item>

        <b-tab-item label="Parameters">
          <div
            class="columns"
            style="margin-bottom: 0px;"
          >
            <div class="column is-5">
              Name
            </div>
            <div class="column is-5">
              Value
            </div>
            <div class="column is-2" />
          </div>
          <div
            v-for="(parameter, index) in apiConnectorInfo.parameters"
            :key="index"
            class="columns"
          >
            <Editor
              v-model="parameter.name"
              :input="true"
              :formula="true"
              class="column is-5"
            />
            <Editor
              v-model="parameter.value"
              :input="true"
              :formula="true"
              class="column is-5"
            />
            <div class="column is-2">
              <b-button
                size="is-small"
                type="is-danger"
                icon-right="delete"
                @click="apiConfigManager.removeParameter(index)"
              />
            </div>
          </div>
          <b-button
            type="is-primary"
            class="sidebar-add"
            @click="apiConfigManager.addParameter"
          >
            <b-icon
              icon="plus"
              size="is-small"
              variant="light"
            />
            Add
          </b-button>
        </b-tab-item>

        <b-tab-item
          v-if="apiConnectorInfo.method !== 'GET'"
          label="Body"
        >
          <div v-if="dataModels.length">
            <Editor
              v-model="apiConnectorInfo.body"
              :input="true"
              :formula="true"
              style="margin-bottom: 20px"
            />
          </div>
          <div v-else>
            <b-notification
              type="is-primary is-light"
              :closable="false"
              class="mt-4 mr-2 has-text-centered"
            >
              Please add atleast one <strong>'Map/Pair Data'</strong> node in module to be able to add payload here.
            </b-notification>
          </div>
        </b-tab-item>

        <b-tab-item label="Authentication">
          <div>
            <BaseSelect
              v-model="apiConnectorInfo.authentication.type"
              placeholder="Select Auth"
            >
              <option
                v-for="(type, index) in options.authentications"
                :key="index"
              >
                {{ type }}
              </option>
            </BaseSelect>

            <div v-if="apiConnectorInfo.authentication.type === 'Basic Auth'">
              <Editor
                v-model="apiConnectorInfo.authentication.data.username"
                :input="true"
                :formula="true"
                label="Username"
                style="margin-bottom: 20px"
              />

              <Editor
                v-model="apiConnectorInfo.authentication.data.password"
                :input="true"
                :formula="true"
                label="Password"
                style="margin-bottom: 20px"
              />
            </div>
            <div v-if="apiConnectorInfo.authentication.type === 'Bearer Token'">
              <Editor
                v-model="apiConnectorInfo.authentication.data.token"
                :input="true"
                :formula="true"
                label="Token"
                style="margin-bottom: 20px"
              />
            </div>

            <div v-if="apiConnectorInfo.authentication.type === 'API Secure Key'">
              <Editor
                v-model="apiConnectorInfo.authentication.data.key"
                :input="true"
                :formula="true"
                label="API Key"
                style="margin-bottom: 20px"
              />
            </div>
          </div>
        </b-tab-item>

        <b-tab-item
          v-if="apiConnectorSidebarConfig.showResponseSection"
          label="Response"
        >
          <div class="p-1">
            <div
              v-for="(variable, index) in apiConnectorInfo.responseVariables"
              :key="index"
            >
              <div class="is-flex is-align-items-end">
                <BaseInput
                  v-model="variable.name"
                  label="Response variable name"
                  class="mb-0 mr-3 w-full"
                />
                <b-button
                  size="is-small"
                  type="is-danger"
                  icon-right="delete"
                  @click="variableManager.removeResponseVariable(index)"
                />
              </div>
            </div>
            <b-button
              expanded
              class="my-3"
              @click="variableManager.addResponseVariable()"
            >
              Add response variable
            </b-button>

            <b-button
              expanded
              type="is-primary"
              @click="variableManager.saveVariables()"
            >
              Save variables
            </b-button>
          </div>
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>

<script >
// @TO-DO's: refactor and split this component into smaller sub-components
// libs
import { computed, ref, watch } from '@vue/composition-api';
import { debouncedWatch } from '@vueuse/core'; // components

import Editor from '@/modules/core/components/wysiwyg/Editor';
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
import BaseInput from '@/modules/core/components/generics/BaseInput.vue'; // stores

import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
import { useModuleStore } from '@/modules/builder/store/moduleStore'; // composables

import { useRoute } from '@/hooks/vueRouter';
import { actionTypes, useActionSidebar } from './actionSidebar';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit; // composables specific to this compoenent

  const useVariableManager = () => {
    const variableManager = {};

    variableManager.saveVariables = async () => {
      try {
        const variables = [...apiConnectorInfo.value.responseVariables.map(responseVariable => ({
          name: responseVariable.name,
          reference: moduleGraphStore.selectedNodeId + responseVariable.name
        })), {
          name: 'HTTP Response Status',
          reference: `${moduleGraphStore.selectedNodeId}_httpResponseStatus`
        }];

        if (!apiConnectorSidebarConfig.value.showResponseSection) {
          variables.push({
            name: 'HTTP Response',
            reference: `${moduleGraphStore.selectedNodeId}_httpResponse`
          });
        }

        if (variables.length) {
          await createVariables(variables);
        }
      } catch (err) {
        console.error(err);
      }
    };

    variableManager.addResponseVariable = () => {
      apiConnectorInfo.value.responseVariables.push({
        name: ''
      });
    };

    variableManager.removeResponseVariable = async index => {
      try {
        const responseVariable = apiConnectorInfo.value.responseVariables[index];
        apiConnectorInfo.value.responseVariables.splice(index, 1);

        if (responseVariable?.name) {
          const referenceId = moduleGraphStore.selectedNodeId + responseVariable.name;
          await deleteStaleVariable([referenceId]);
        }
      } catch (err) {
        console.error(err);
      }
    };

    const deleteStaleVariable = async references => {
      try {
        const {
          appId
        } = route.params;
        const {
          moduleId
        } = moduleStore;
        await moduleStore.deleteVariables(appId, moduleId, references);
      } catch (err) {
        console.error(err);
      }
    };

    const createVariables = async variables => {
      try {
        const {
          appId
        } = route.params;
        const {
          moduleId
        } = moduleStore;
        await moduleStore.createVariable(appId, moduleId, {
          module_id: moduleId,
          node_id: moduleGraphStore.selectedNodeId,
          node_name: apiConnectorInfo.value.name,
          variables
        });
      } catch (err) {
        console.error(err);
      }
    };

    return variableManager;
  };

  const useApiConfigManager = () => {
    const apiConfigManager = {};

    apiConfigManager.removeParameter = index => {
      apiConnectorInfo.value.parameters.splice(index, 1);
    };

    apiConfigManager.addParameter = () => {
      apiConnectorInfo.value.parameters.push({
        name: '',
        value: ''
      });
    };

    apiConfigManager.removeHeader = index => {
      apiConnectorInfo.value.headers.splice(index, 1);
    };

    apiConfigManager.addHeader = () => {
      apiConnectorInfo.value.headers.push({
        name: '',
        value: ''
      });
    };

    return apiConfigManager;
  };

  const route = useRoute();
  const moduleGraphStore = useModuleGraphStore();
  const moduleStore = useModuleStore();
  const {
    actionsConfig
  } = useActionSidebar();
  const variableManager = useVariableManager();
  const apiConfigManager = useApiConfigManager();
  const defaultValue = {
    name: 'APIConnector',
    application_id: null,
    module_id: null,
    method: '',
    url: 'https://',
    headers: [{
      name: '',
      value: ''
    }],
    parameters: [{
      name: '',
      value: ''
    }],
    authentication: {
      type: '',
      data: {}
    },
    body: null,
    responseVariables: []
  };
  const apiConnectorInfo = ref({});

  const loadInfo = () => {
    apiConnectorInfo.value = { ...defaultValue,
      ...props.value
    };
  };

  loadInfo();
  watch(() => apiConnectorInfo.value, () => {
    emit('input', apiConnectorInfo.value);
  }, {
    deep: true
  });
  debouncedWatch(() => apiConnectorInfo.value.name, () => {
    variableManager.saveVariables();
  }, {
    debounce: 1000
  });
  const activeTab = ref(0);
  const options = ref({
    methods: ['POST', 'GET', 'PUT', 'PATCH', 'DELETE'],
    authentications: ['None', 'API Secure Key', 'Basic Auth', 'Bearer Token'],
    bodyObjects: ['Map/Pair Model #1']
  });
  const dataModels = computed(() => {
    const models = moduleGraphStore.actionNodes.filter(action => action.data.type === actionTypes.DATA_MAPPER).map(action => ({
      name: action.data.name,
      id: action.nodeId
    }));
    return models;
  });
  const apiConnectorSidebarConfig = computed(() => actionsConfig.value[actionTypes.API_CONNECTOR]);
  return {
    variableManager,
    apiConfigManager,
    apiConnectorInfo,
    activeTab,
    options,
    dataModels,
    apiConnectorSidebarConfig
  };
};

__sfc_main.components = Object.assign({
  BaseInput,
  BaseSelect,
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';

.sidebar-del-row button {
  width: 30px;
  height: 30px;
  background: rgba(251, 131, 117, 0.2);
  border-radius: 8px;
  border-color: transparent;
}

.sidebar-del-row button:hover {
  border-color: transparent;
}

.sidebar-add {
  width: 198px;
  height: 40px;
  background: $primary;
  border-radius: 8px;
}

.custom-api-tabs {
  .tabs ul {
    border-bottom: 2px solid $grey;
  }
  .tabs a {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #222222;
    border-bottom: 0px;
    padding: 12px 20px;
    margin-bottom: -2px;
  }
  .tabs li.is-active a {
    border-bottom: 2px solid $primary;
    color: $primary;
  }
}
</style>
