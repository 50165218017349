<template>
  <div />
</template>

<script >
import { ref } from '@vue/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const defaultValue = {
    name: 'Start'
  };
  const executeAlwaysInfo = ref({});

  const loadInfo = () => {
    executeAlwaysInfo.value = { ...defaultValue,
      ...props.value
    };
  };

  loadInfo();
  return {};
};

export default __sfc_main;
</script>
