<template>
  <div v-if="basicProperties.columns">
    <div class="mt-2">
      <DynamicValueSwitch
        v-model="basicProperties.hasDefaultSorting"
        :is-dynamic="basicProperties.isDefaultSortingDynamic"
        label="Default Sorting"
        @update-dynamic-switch="updateDynamicSwitch($event)"
      />
      <div
        v-if="basicProperties.hasDefaultSorting || basicProperties.isDefaultSortingDynamic"
        class="mt-2"
      >
        <div class="data-table-column">
          <BaseLabel class="column is-6 p-0">
            Column
          </BaseLabel>
          <BaseLabel class="column is-6 p-0">
            Sort Direction
          </BaseLabel>
        </div>
        <div
          :key="`default_sorting_column`"
          class="data-table-column mt-1"
        >
          <BaseSelect
            v-model="basicProperties.defaultSortingColumn"
            class="column is-5 p-0 ml-2"
            expanded
            size="is-small"
            placeholder="Select a column"
          >
            <template v-for="(column, lIndex) in basicProperties.columns">
              <option
                v-if="column.visible"
                :key="lIndex"
              >
                {{ column.label }}
              </option>
            </template>
          </BaseSelect>
          <BaseSelect
            v-model="basicProperties.sortingOrder"
            class="column is-6 p-0 ml-2"
            expanded
            size="is-small"
            placeholder="Sorting order"
          >
            <option value="ascending">
              Ascending
            </option>
            <option value="descending">
              Descending
            </option>
          </BaseSelect>
        </div>
      </div>
    </div>
    <div class="mt-2">
      <DynamicValueSwitch
        v-model="basicProperties.hasDefaultFiltering"
        :is-dynamic="basicProperties.isDefaultFilteringDynamic"
        label="Default Filtering"
        @update-dynamic-switch="updateFilteringDynamicSwitch($event)"
      />
      <div
        v-if="basicProperties.hasDefaultFiltering || basicProperties.isDefaultFilteringDynamic"
        class="mt-2"
      >
        <div class="data-table-column">
          <BaseLabel class="column is-6 p-0">
            Column
          </BaseLabel>
          <BaseLabel class="column is-5 p-0">
            Keywords
          </BaseLabel>
        </div>
        <template v-for="(defaultFilteringColumn, index) in basicProperties.defaultFilteringColumns">
          <div
            v-if="index !== undefined"
            :key="`default_filtering_column_${index}`"
            class="data-table-column mt-1"
          >
            <BaseSelect
              v-model="basicProperties.defaultFilteringColumns[index].label"
              class="column is-5 p-0 ml-2"
              expanded
              size="is-small"
              placeholder="Select a column"
            >
              <template v-for="(column, lIndex) in basicProperties.columns">
                <option
                  v-if="column.visible"
                  :key="lIndex"
                >
                  {{ column.label }}
                </option>
              </template>
            </BaseSelect>
            <Editor
              v-model="basicProperties.defaultFilteringColumns[index].keywords"
              :input="true"
              class="ml-2 column is-5 p-0"
            />
            <b-button
              type="is-danger"
              size="is-small"
              icon-right="delete"
              class="ml-2"
              @click="deleteDefaultFilteringColumn(index)"
            />
          </div>
        </template>
        <b-button
          class="mt-2"
          size="is-small"
          expanded
          type="is-primary"
          @click="addDefaultFilteringColumn()"
        >
          Add Column
        </b-button>
      </div>
    </div>
    <div
      class="mt-2"
    >
      <BaseLabel class="column is-6 p-0">
        Unique ID
      </BaseLabel>
      <div>
        <Editor
          v-model="basicProperties.columns[0].value"
          :input="true"
        />
      </div>
    </div>
    <div
      class="mt-2"
    >
      <div class="is-flex is-justify-content-space-between">
        <BaseLabel class="mt-2">
          Columns
        </BaseLabel>
        <b-button
          class="mt-2"
          size="is-small"
          type="is-primary"
          :disabled="!idColumnValue"
          @click="autoMap()"
        >
          Auto Map
        </b-button>
      </div>
      <div class="data-table-column mt-2">
        <BaseLabel class="column is-6 p-0">
          Label
        </BaseLabel>
        <BaseLabel class="column is-5 p-0">
          Value
        </BaseLabel>
      </div>
      <Draggable
        v-model="basicProperties.columns"
        handle=".drag-handle"
      >
        <template v-for="(label, index) in basicProperties.columns">
          <div
            v-if="index > 0"
            :key="index"
            class="data-table-column mt-2"
          >
            <b-icon
              icon="drag-horizontal-variant"
              class="drag-handle mr-1"
            />
            <BaseInput
              v-model="basicProperties.columns[index].label"
              type="text"
            />
            <Editor
              v-model="basicProperties.columns[index].value"
              :input="true"
              class="ml-2"
            />
            <b-button
              type="is-danger"
              size="is-small"
              icon-right="delete"
              class="ml-2"
              @click="deleteColumn(index)"
            />
          </div>
        </template>
      </Draggable>
      <b-button
        class="mt-2"
        size="is-small"
        expanded
        type="is-primary"
        @click="addColumn()"
      >
        Add Column
      </b-button>
    </div>
  </div>
</template>

<script >
// libs
import { computed, set, onMounted } from '@vue/composition-api';
import Draggable from 'vuedraggable'; // components

import Editor from '@/modules/core/components/wysiwyg/Editor';
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
import BaseLabel from '@/modules/core/components/generics/BaseLabel.vue'; // stores

import { useModuleStore } from '@/modules/builder/store/moduleStore'; // composables

import { useBasicProperties } from '@/modules/builder/components/form-builder/properties/field-properties/basic-properties/basicProperties'; // others

import { extractReferenceId } from '@/helpers/ExpressionParser';
import { delay } from '@/helpers/util';
import DynamicValueSwitch from '@/modules/core/components/DynamicValueSwitch.vue';
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const {
    basicProperties
  } = useBasicProperties();
  const moduleStore = useModuleStore();
  const idColumnValue = computed(() => {
    if (basicProperties.value?.columns?.length && basicProperties.value.columns[0].value) {
      const idReference = extractReferenceId(basicProperties.value.columns[0].value);
      return idReference;
    }

    return null;
  });
  const localDBNodeId = computed(() => {
    if (idColumnValue.value) {
      return idColumnValue.value.split('-').slice(0, 5).join('-');
    }

    return '';
  });

  const deleteColumn = index => {
    const columnToRemove = { ...basicProperties.value.columns[index]
    };
    basicProperties.value.columns.splice(index, 1);
    const removeDefaultSortingIndex = basicProperties.value.defaultSortingColumns.findIndex(column => column.label === columnToRemove.label);

    if (removeDefaultSortingIndex > -1) {
      deleteDefaultSortingColumn(removeDefaultSortingIndex);
    }

    const removeDefaultFilteringIndex = basicProperties.value.defaultFilteringColumns.findIndex(column => column.label === columnToRemove.label);

    if (removeDefaultFilteringIndex > -1) {
      deleteDefaultFilteringColumn(removeDefaultSortingIndex);
    }
  };

  const addColumn = () => {
    basicProperties.value.columns.push({
      label: '',
      value: '',
      visible: true
    });
  };

  const deleteDefaultSortingColumn = index => {
    basicProperties.value.defaultSortingColumns.splice(index, 1);
  };

  const addDefaultSortingColumn = () => {
    basicProperties.value.defaultSortingColumns.push({
      label: '',
      direction: 'ascending'
    });
  };

  const deleteDefaultFilteringColumn = index => {
    basicProperties.value.defaultFilteringColumns.splice(index, 1);
  };

  const addDefaultFilteringColumn = () => {
    basicProperties.value.defaultFilteringColumns.push({
      label: '',
      keywords: ''
    });
  };

  const autoMap = () => {
    if (localDBNodeId.value) {
      const columnMappings = moduleStore.moduleVariables.filter(variable => variable.node_id === localDBNodeId.value && variable.reference !== idColumnValue.value).map(variable => ({
        label: variable.name.split(' > ').slice(-1)[0],
        value: `<p><span class="mention" data-mention-id="${variable.reference}" name="${variable.name}">@${variable.name.replaceAll(' > ', ' &gt; ')}</span> </p>`,
        visible: true
      })); // clear existing mapping aside from ID column

      basicProperties.value.columns = basicProperties.value.columns.slice(0, 1); // assign auto mapped columns

      basicProperties.value.columns = basicProperties.value.columns.concat(columnMappings);
    }
  };

  const updateDynamicSwitch = value => {
    if ('isDefaultSortingDynamic' in basicProperties.value) {
      basicProperties.value['isDefaultSortingDynamic'] = value;
    } else {
      set(basicProperties.value, 'isDefaultSortingDynamic', value);
    }
  };

  const updateFilteringDynamicSwitch = value => {
    if ('isDefaultFilteringDynamic' in basicProperties.value) {
      basicProperties.value['isDefaultFilteringDynamic'] = value;
    } else {
      set(basicProperties.value, 'isDefaultFilteringDynamic', value);
    }
  };

  onMounted(async () => {
    await delay(500); // Note :-  Have added this logic for backward compatibility

    if (basicProperties.value.defaultSortingColumns?.length) {
      set(basicProperties.value, 'sortingOrder', basicProperties.value.defaultSortingColumns[0].direction);
      set(basicProperties.value, 'defaultSortingColumn', basicProperties.value.defaultSortingColumns[0].label);
    }

    if (basicProperties.value.defaultFilteringColumns === undefined) {
      set(basicProperties.value, 'defaultFilteringColumns', [{
        label: '',
        keywords: ''
      }]);
    }
  });
  return {
    basicProperties,
    idColumnValue,
    deleteColumn,
    addColumn,
    deleteDefaultFilteringColumn,
    addDefaultFilteringColumn,
    autoMap,
    updateDynamicSwitch,
    updateFilteringDynamicSwitch
  };
};

__sfc_main.components = Object.assign({
  DynamicValueSwitch,
  BaseLabel,
  BaseSelect,
  Editor,
  Draggable,
  BaseInput
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
.data-table-column {
  display: flex;

  > div {
    flex: 1;
  }
  .drag-handle {
    cursor: pointer; 
  }
}
</style>
