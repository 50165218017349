<template>
  <b-dropdown
    :ref="`moduleActionDropdown`"
    aria-role="list"
    position="is-bottom-left"
    class="app-action-dropdown"
    append-to-body
    @active-change="handleUpdate"
  >
    <b-dropdown-item
      aria-role="listitem"
      class="is-flex is-align-items-center"
      @click="handleAction(tableActions.INFO)"
    >
      <b-icon
        icon="information-outline"
        type="is-black"
        custom-size="mdi-22px"
        class="mr-2"
      />
      Info
    </b-dropdown-item>
    <b-dropdown-item
      aria-role="listitem"
      class="is-flex is-align-items-center"
      @click="handleAction(tableActions.EDIT)"
    >
      <b-icon
        icon="pencil-outline"
        type="is-black"
        custom-size="mdi-22px"
        class="mr-2"
      />
      Edit
    </b-dropdown-item>
    <b-dropdown-item
      aria-role="listitem"
      class="is-flex is-align-items-center"
      @click="handleAction(tableActions.OPEN_VERSIONS)"
    >
      <b-icon
        icon="history"
        type="is-black"
        custom-size="mdi-22px"
        class="mr-2"
      />
      Versions
    </b-dropdown-item>
    <b-dropdown-item
      v-if="moduleInfo.type === 'Web app/Screens' || moduleInfo.type === 'Web app/Auth'"
      aria-role="listitem"
      class="is-flex is-align-items-center"
      @click="handleAction(tableActions.OPEN_ANALYTICS)"
    >
      <b-icon
        icon="poll"
        type="is-black"
        custom-size="mdi-22px"
        class="mr-2"
      />
      Analytics
    </b-dropdown-item> 
    <b-dropdown-item
      aria-role="listitem"
      class="is-flex is-align-items-center"
      @click="handleAction(tableActions.DUPLICATE)"
    >
      <b-icon
        icon="content-copy"
        type="is-black"
        custom-size="mdi-22px"
        class="mr-2"
      />
      Duplicate
    </b-dropdown-item>
    <b-dropdown-item
      aria-role="listitem"
      custom
      class="is-flex is-align-items-center is-justify-content-center"
    >
      <b-button
        icon-left="delete-outline"
        size="is-small"
        type="is-danger is-light"
        class="rounded-8 w-full"
        @click.stop="handleAction(tableActions.DELETE)"
      >
        Delete
      </b-button>
    </b-dropdown-item>
  </b-dropdown>
</template>
  
<script >
import { computed, getCurrentInstance, watch } from '@vue/composition-api';
import { useModuleTable } from './moduleTable';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Boolean,
    default: false
  },
  moduleInfo: {
    type: Object,
    default: () => ({})
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit; // @VUE3: do not use this function when migrating to vue 3

  const vm = getCurrentInstance();
  const {
    tableActions
  } = useModuleTable();
  const isDropdownVisible = computed({
    get() {
      return props.value;
    },

    set(val) {
      // @VUE3: use functional template ref in vue 3 instead (check offical vue 3 docs for info), vue 2 composition api plugin doesn't support them
      vm.proxy.$refs.moduleActionDropdown.isActive = val;
    }

  });

  const setDropdownVisibility = visibility => {
    isDropdownVisible.value = visibility;
  };
  /** 
   * @param {string} option
   */


  const handleAction = option => {
    setDropdownVisibility(false);
    emit('select', option);
  };

  watch(() => props.value, () => {
    setDropdownVisibility(props.value);
  });

  const handleUpdate = value => {
    emit('input', value);
  };

  return {
    tableActions,
    handleAction,
    handleUpdate
  };
};

export default __sfc_main;
</script>
