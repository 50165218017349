<template>
  <div class="mt-2">
    <b-field
      label="Tiles Alignment"
      custom-class="base-label default"
    >
      <b-select
        v-model="basicProperties.tilesAlignment"
        size="is-small"
        expanded
      >
        <option
          v-for="(option, index) in alignmentOptions"
          :key="index"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </b-select>
    </b-field>
    <b-field
      label="Content Arrangement"
      custom-class="base-label default"
    >
      <b-select
        v-model="basicProperties.contentArrangement"
        size="is-small"
        expanded
        @input="onArrangementChange"
      >
        <option
          v-for="(option, index) in contentArrangementOptions"
          :key="index"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </b-select>
    </b-field>
    <BaseInput
      v-model.number="basicProperties.numberOfRows"
      type="number"
      min="1"
      class="mt-2"
      label="Rows"
      @input="updateTiles"
    />
    <BaseInput
      v-model.number="basicProperties.numberOfCols"
      type="number"
      min="1"
      class="mt-2"
      label="Columns"
      @input="updateTiles"
    />
    <BaseInput
      v-model.number="basicProperties.size"
      type="number"
      min="120"
      max="500"
      class="mt-2"
      label="Tile Size"
      has-tooltip
      :tooltip-props="{
        label: 'Enter the tile size in pixels (e.g., 130).',
        position : 'is-right'
      }"
    />
    <BaseInput
      v-model.number="basicProperties.imageSize"
      type="number"
      min="50"
      max="200"
      class="mt-2"
      label="Image Size"
      has-tooltip
      :tooltip-props="{
        label: 'Enter the tile size in pixels (e.g., 60).',
        position: 'is-right'
      }"
    />
  </div>
</template>

<script >
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
import { useBasicProperties } from '../basicProperties';
import { uuid } from 'vue-uuid';
const __sfc_main = {};
__sfc_main.props = {
  selectedFieldType: {
    type: String,
    default: 'field'
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const alignmentOptions = [{
    value: 'is-justify-content-start',
    label: 'Start'
  }, {
    value: 'is-justify-content-end',
    label: 'End'
  }, {
    value: 'is-justify-content-center',
    label: 'Center'
  }, {
    value: 'is-justify-content-space-between',
    label: 'Space Between'
  }, {
    value: 'is-justify-content-space-around',
    label: 'Space Around'
  }, {
    value: 'is-justify-content-space-evenly',
    label: 'Space Evenly'
  }];
  const contentArrangementOptions = [{
    value: 'image-first',
    label: 'Image first'
  }, {
    value: 'text-first',
    label: 'Text first'
  }, {
    value: 'custom',
    label: 'Custom'
  }];
  const props = __props;
  const {
    basicProperties
  } = useBasicProperties(props.selectedFieldType);

  const rearrangeTiles = (rows, cols) => {
    const totalTilesCount = rows * cols;
    const currentTilesCount = basicProperties.value.tiles.length;
    let tiles = [...basicProperties.value.tiles];

    if (currentTilesCount < totalTilesCount) {
      for (let current = currentTilesCount + 1; current <= totalTilesCount; current++) {
        tiles.push({
          id: uuid.v4(),
          label: 'Title',
          arrangeImageFirst: true,
          image: null,
          image_url: null,
          file_upload: false
        });
      }
    } else if (currentTilesCount > totalTilesCount) {
      tiles = tiles.splice(0, totalTilesCount);
    }

    basicProperties.value.tiles = [...tiles];
  };

  const updateTiles = () => {
    const rows = basicProperties.value.numberOfRows;
    const cols = basicProperties.value.numberOfCols;

    if (rows && cols) {
      rearrangeTiles(rows, cols);
    }
  };

  const onArrangementChange = () => {
    let imagefirst;

    if (basicProperties.value.contentArrangement === 'image-first') {
      imagefirst = true;
    } else if (basicProperties.value.contentArrangement === 'text-first') {
      imagefirst = false;
    }

    if (imagefirst !== undefined) {
      for (let tile of basicProperties.value.tiles) {
        tile.arrangeImageFirst = imagefirst;
      }
    }
  };

  return {
    alignmentOptions,
    contentArrangementOptions,
    basicProperties,
    updateTiles,
    onArrangementChange
  };
};

__sfc_main.components = Object.assign({
  BaseInput
}, __sfc_main.components);
export default __sfc_main;
</script>