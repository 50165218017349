<template>
  <div class="mt-2">
    <DynamicValueSwitch
      v-model="validationProperties.rangeErrorMessage"
      :is-dynamic="validationProperties.isRangeErrorMessageDynamic"
      label="Range error message"
      class="mt-1 mb-2"
      custom-switch-classes="is-flex-direction-column-reverse"
      dynamic-switch-classes="ml-auto"
      @update-dynamic-switch="toggleDynamicSwitch"
    >
      <BaseInput
        v-model="validationProperties.rangeErrorMessage"
        label="Range error message"
        expanded
      />
    </DynamicValueSwitch>
  </div>
</template>

<script >
import { set } from '@vue/composition-api';
import { useValidationProperties } from '@/modules/builder/components/form-builder/properties/field-properties/validation-properties/validationProperties';
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
import DynamicValueSwitch from '@/modules/core/components/DynamicValueSwitch.vue';
const __sfc_main = {};
__sfc_main.props = {
  selectedFieldType: {
    type: String,
    default: 'field'
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const {
    validationProperties
  } = useValidationProperties(props.selectedFieldType);

  const toggleDynamicSwitch = isDynamic => {
    set(validationProperties.value, 'rangeErrorMessage', '');
    set(validationProperties.value, 'isRangeErrorMessageDynamic', isDynamic);
  };

  return {
    validationProperties,
    toggleDynamicSwitch
  };
};

__sfc_main.components = Object.assign({
  DynamicValueSwitch,
  BaseInput
}, __sfc_main.components);
export default __sfc_main;
</script>