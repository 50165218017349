<template>
  <div>
    <b-dropdown
      v-model="selectedFontSize"
      scrollable
      aria-role="list"
      class="font-size-selector"
    >
      <template #trigger>
        <slot />
      </template>
      <b-dropdown-item
        v-for="(fontSize, fIndex) in fontSizeList"
        :key="'font-size' + fIndex"
        :value="fontSize"
        aria-role="listitem"
      >
        <span>{{ fontSize }}</span>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>
  
<script >
import { computed, ref } from '@vue/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: String,
    default: ''
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;

  const generateFontSizes = limit => {
    return Array.from({
      length: limit
    }, (_, index) => `${2 * (index + 1)}px`);
  };

  const fontSizeList = ref(['Default', ...generateFontSizes(40)]);
  const selectedFontSize = computed({
    get() {
      return props.value || 'Default';
    },

    set(value) {
      value = value === 'Default' ? '' : value;
      emit('input', value);
    }

  });
  return {
    fontSizeList,
    selectedFontSize
  };
};

export default __sfc_main;
</script>

<style lang="scss">
.font-size-selector {
    z-index: 5000;
}
</style>
