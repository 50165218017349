<template>
  <div class="mb-2">
    <div class="columns is-multiline p-0">
      <div class="column is-6 py-1">
        <BaseLabel class="mt-2">
          Street
        </BaseLabel>
        <Editor
          v-model="basicProperties.defaultValue.street"
          :input="true"
        />
      </div>
      <div class="column is-6 py-1">
        <BaseLabel class="mt-2">
          Street Number
        </BaseLabel>
        <Editor
          v-model="basicProperties.defaultValue.number"
          :input="true"
        />
      </div>
      <div class="column is-6 py-1">
        <BaseLabel class="mt-2">
          Zip Code
        </BaseLabel>
        <Editor
          v-model="basicProperties.defaultValue.zipCode"
          :input="true"
        />
      </div>
      <div class="column is-6 py-1">
        <BaseLabel class="mt-2">
          City
        </BaseLabel>
        <Editor
          v-model="basicProperties.defaultValue.city"
          :input="true"
        />
      </div>
      <div class="column is-6 py-1">
        <BaseLabel class="mt-2">
          Country
        </BaseLabel>
        <Editor
          v-model="basicProperties.defaultValue.country"
          :input="true"
        />
      </div>
    </div>
  </div>
</template>

<script >
import { useBasicProperties } from '@/modules/builder/components/form-builder/properties/field-properties/basic-properties/basicProperties';
import Editor from '@/modules/core/components/wysiwyg/Editor';
import BaseLabel from '@/modules/core/components/generics/BaseLabel.vue';
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const {
    basicProperties
  } = useBasicProperties();
  return {
    basicProperties
  };
};

__sfc_main.components = Object.assign({
  BaseLabel,
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>
