<template>
  <li
    class="tree-list-item"
    :style="{
      ...(treeHorizontalOffset.right ? { 'margin-right': `${treeHorizontalOffset.right}px` } : {}),
      ...(treeHorizontalOffset.left ? { 'margin-left': `${treeHorizontalOffset.left}px` } : {})
    }"
  >
    <TreeNode
      ref="treeNodeComponent"
      :node="node"
      :node-components="nodeComponents"
      :style="{
        ...(node.isStart ? { 'margin-top': `${treeVerticalOffset.top}px` } : {})
      }"
    />
    <div
      v-if="node.children && node.children.length"
      class="tree-list-container"
    >
      <ul
        class="tree-list"
        :style="{
          'margin-bottom': `${treeVerticalOffset.bottom}px`
        }"
      >
        <SubTree
          v-for="(childNode, cIndex) in node.children"
          :key="cIndex"
          :node="childNode"
          :node-components="nodeComponents"
          :tree-nodes="treeNodes"
        />
      </ul>
    </div>
  </li>
</template>

<script >
import { computed, defineAsyncComponent } from '@vue/composition-api';
import TreeNode from './TreeNode.vue';
import { useTreeConnections } from './treeConnections';
import { getNodesByLevel, getNodeLevel } from './treeFlowInternal'; //-- child components --//

const SubTree = defineAsyncComponent(() => import('./SubTree.vue')); //-- component props --//

const __sfc_main = {};
__sfc_main.props = {
  nodeComponents: {
    type: Array,
    default: () => [],
    description: 'custom components to be rendered as tree nodes'
  },
  node: {
    type: Object,
    default: () => {},
    description: 'node passed recursively to generate tree'
  },
  treeNodes: {
    type: Array,
    default: () => []
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props; //-- compose hooks --//

  const {
    getConnectionOffsetsByNodeIds
  } = useTreeConnections();
  const treeVerticalOffset = computed(() => {
    const siblingNodes = getNodesByLevel(props.treeNodes, getNodeLevel(props.node));
    const nodeIds = [...siblingNodes.map(node => node.nodeId), props.node.nodeId];
    const connectionOffsets = getConnectionOffsetsByNodeIds(nodeIds);
    const bottomVerticalOffsets = connectionOffsets.map(connectionOffset => connectionOffset.yOffset);
    const topVerticalOffsets = props.node.isStart ? connectionOffsets.filter(connectionOffset => connectionOffset.isDirectConnection && connectionOffset.targetNodeId === props.node.nodeId).map(connectionOffset => connectionOffset.yOffset) : [];
    return {
      top: Math.max(...topVerticalOffsets),
      bottom: bottomVerticalOffsets.length ? Math.max(...bottomVerticalOffsets) : 0
    };
  });
  const treeHorizontalOffset = computed(() => {
    const connectionOffsets = getConnectionOffsetsByNodeIds([props.node.nodeId]);
    const rightHorizontalOffsets = connectionOffsets.filter(connection => connection.xDirectionFromSource === 'right').map(connection => connection.xOffset);
    const leftHorizontalOffsets = connectionOffsets.filter(connection => connection.xDirectionFromSource === 'left').map(connection => connection.xOffset);
    return {
      right: rightHorizontalOffsets.length ? Math.max(...rightHorizontalOffsets) : 0,
      left: leftHorizontalOffsets.length ? Math.max(...leftHorizontalOffsets) : 0
    };
  });
  return {
    treeVerticalOffset,
    treeHorizontalOffset
  };
};

__sfc_main.components = Object.assign({
  TreeNode,
  SubTree
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import "~@/style/variables.scss";

.tree-list-container {
  position: relative;
}

.tree-list {
  position: relative;
  transition: all 0.5s;
}

.tree {
  >.tree-list {
    >.tree-list-item {
      >.tree-list-container {
        >.tree-list {
          >.tree-list-item { 
            padding-left: 1rem; // applies horizontal spacing to first level of tree only 
            padding-right: 1rem;
          }
        }
      }
    }
  }
}

.tree-list-item {
  position: relative;
  float: left;
  text-align: center;
  list-style-type: none;
  padding-top: 4.125rem;
  transition: all 0.5s;
  &:hover {
    z-index: 10;
  }
}
</style>
