<template>
  <BaseInput
    :key="'iban-' + renderKey"
    size="is-small"
    :disabled="field.properties.basic.isDisabled"
    :placeholder="field.properties.basic.hasPlaceholder ? field.properties.basic.placeholder : ''"
    :value="value"
    :maxlength="field.properties.basic.hasLimit ? field.properties.basic.limit : ''"
    :mask="ibanMask"
  />
</template>

<script >
import { computed, ref, watch } from '@vue/composition-api';
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const ibanMask = {
    blocks: [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
    uppercase: true
  };
  const renderKey = ref(0);
  const value = computed(() => props.field.properties.basic.hasDefaultValue ? props.field.properties.basic.defaultValue : '');
  watch(() => {
    return {
      defaultValue: props.field.properties.basic.defaultValue,
      hasDefaultValue: props.field.properties.basic.hasDefaultValue
    };
  }, () => {
    renderKey.value++;
  }, {
    immediate: true,
    deep: true
  });
  return {
    ibanMask,
    renderKey,
    value
  };
};

__sfc_main.components = Object.assign({
  BaseInput
}, __sfc_main.components);
export default __sfc_main;
</script>
