<template>
  <div
    class="base-input"
    :class="variant"
  >
    <div class="is-flex">
      <BaseLabel
        v-bind="labelProps"
        :custom-class="`base-label ${labelProps.customClass || 'default'}`"
      >
        {{ label }}
      </BaseLabel>
      <!-- Tooltip for the label -->
      <b-tooltip
        v-if="hasTooltip"
        target="label-tooltip"
        :triggers="['hover']"
        class="ml-2"
        v-bind="tooltipProps"
        multilined
      >
        <b-icon
          icon="help-circle-outline"
          size="is-small"
        />
      </b-tooltip>
    </div>
    <b-field>
      <b-input
        v-model="maskedValue"
        v-bind="$attrs"
        :data-mask="inputElementUniqueId"
      />
    </b-field>
  </div>
</template>

<script >
import { computed, ref } from '@vue/composition-api';
import { useMask } from './mask';
import { uuid } from 'vue-uuid';
import BaseLabel from './BaseLabel.vue';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: [String, Number],
    default: ''
  },
  mask: {
    type: Object,
    default: null
  },
  variant: {
    type: String,
    default: 'default'
  },
  label: {
    type: String,
    default: ''
  },
  labelProps: {
    type: Object,
    default: () => ({})
  },
  hasTooltip: {
    type: Boolean,
    default: false
  },
  tooltipProps: {
    type: Object,
    default: () => ({})
  },
  tooltipContent: {
    type: String,
    default: ''
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const inputElementUniqueId = ref(uuid.v4());
  const inputElementSelector = computed(() => inputElementUniqueId.value ? `[data-mask="${inputElementUniqueId.value}"]` : '');
  const {
    maskedValue
  } = useMask(props, emit, inputElementSelector.value);
  return {
    inputElementUniqueId,
    maskedValue
  };
};

__sfc_main.components = Object.assign({
  BaseLabel
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';
@import '~@/modules/core/components/generics/styles/label.scss';

.base-input {
  &.default {
    margin-bottom: 10px;

    .input {
      border: 1px solid $grey-9;
      border-radius: 8px !important;
      font-weight: 400;
      font-size: 13px !important;
      line-height: 19px;
      color: $dark;
      padding: 10px 14px;
      &:focus {
        box-shadow: none;
      }
    }
  }

  &.list {
    margin-bottom: 0px;

    .input {
      border: 1px solid $grey-9;
      border-radius: 8px !important;
      font-weight: 400;
      font-size: 13px !important;
      line-height: 19px;
      color: $dark;
      padding: 10px 14px;
    }
    .input:focus {
      box-shadow: none;
      border: 1px solid $grey-9;
    }
  }

  /** Override bulma styling */ 
  .label:not(:last-child) {
    margin-bottom: 6px !important;
  }
}
</style>