<template>
  <Node
    :node-id="nodeId"
    :icon="currentIcon"
    class="action-node"
    shape="trapezoid"
  />
</template>

<script >
// libs
import { computed } from '@vue/composition-api'; // components

import Node from './Node.vue'; // stores

import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore'; // composables

import { useActionSidebar } from '@/modules/builder/components/module-sidebar/action/actionSidebar'; //-- component props --//

const __sfc_main = {};
__sfc_main.props = {
  nodeId: {
    type: String,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props; //-- composables --//

  const moduleGraphStore = useModuleGraphStore();
  const {
    allowedActionsList
  } = useActionSidebar(); // -- current - //

  const currentNode = computed(() => moduleGraphStore.getNodeById(props.nodeId) || {});
  const currentIcon = computed(() => {
    if (currentNode.value) {
      return (allowedActionsList.value.find(action => action.type === currentNode.value.data.type) || {
        icon: 'view-dashboard-outline'
      }).icon;
    }

    return 'view-dashboard-outline';
  });
  return {
    currentIcon
  };
};

__sfc_main.components = Object.assign({
  Node
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';

.action-node {
  .node-icon {
    background: $primary;
  }
}
</style>
