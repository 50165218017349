<template>
  <div
    v-if="selectedField && selectedField.properties"
  >
    <BasicProperties />
    <ValidationProperties 
      v-if="isValidationPropertyVisible"
      :selected-field-type="selectedField.type === 'grid' ? 'gridCell' : selectedField.type"
    />
    <Conditions 
      v-if="selectedField.condition && (selectedField.type === 'text' || !moduleGraphStore.isAuthScreen)"
    />
    <b-button
      v-if="selectedField.type === 'text' || !moduleGraphStore.isAuthScreen"
      class="mt-2"
      size="is-small"
      expanded
      type="is-danger"
      @click="confirmDeleteField()"
    >
      Delete field
    </b-button>
  </div>
</template>

<script >
// libs
import { computed } from '@vue/composition-api'; // components

import BasicProperties from './basic-properties/BasicProperties.vue';
import ValidationProperties from './validation-properties/ValidationProperties.vue';
import Conditions from './Conditions.vue'; // stores

import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
import { useModuleStore } from '@/modules/builder/store/moduleStore'; // composables

import { useFormBuilder } from '@/modules/builder/components/form-builder/formBuilder';
import { useBuefy } from '@/hooks/buefy';
import { useRoute } from '@/hooks/vueRouter';
import { useGrid } from './basic-properties/components/grid/grid';
import { useBasicProperties } from './basic-properties/basicProperties'; //-- use composables --//

const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const {
    selectedField,
    selectedFields,
    selectedFieldIndices
  } = useFormBuilder();
  const {
    basicProperties
  } = useBasicProperties();
  const {
    selectedGridCells
  } = useGrid();
  const buefy = useBuefy();
  const route = useRoute();
  const moduleGraphStore = useModuleGraphStore();
  const moduleStore = useModuleStore();
  const isValidationPropertyVisible = computed(() => {
    if (selectedGridCells.value.length && selectedField.value.type === 'grid') {
      const {
        rowIndex,
        columnIndex
      } = selectedGridCells.value[0];
      const cellRow = basicProperties.value?.cells?.data[rowIndex];
      const cellElement = cellRow ? cellRow[columnIndex]?.properties?.basic.element : null;

      if (cellElement) {
        return cellElement.properties?.validation && Object.keys(cellElement.properties.validation).length > 0;
      }

      return false;
    }

    return selectedField.value.properties.validation && Object.keys(selectedField.value.properties.validation).length > 0;
  }); //-- delete field logic --//

  const confirmDeleteField = () => {
    buefy.dialog.confirm({
      title: 'Deleting field',
      message: 'Are you sure you want to <b>delete</b> this field? This action cannot be undone.',
      confirmText: 'Delete field',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => {
        deleteField();
        buefy.toast.open('Field deleted!');
      }
    });
  };

  const deleteField = async () => {
    try {
      const {
        appId,
        moduleId
      } = route.params;

      if (selectedField.value && selectedField.value.id) {
        const references = moduleStore.moduleVariables.filter(variable => variable.reference.includes(selectedField.value.id)).map(variable => variable.reference);
        await moduleStore.deleteVariables(appId, moduleId, references); // @NOTE: Removing variable references from JSON may not be required once we have better variable search feature in builder
        // delete variable references from UI
        // moduleGraphStore.deleteVariableReferencesFromAllScreens([selectedField.value.id]);

        selectedFields.value.splice(selectedFieldIndices.value.fieldIndex, 1);
        selectedFieldIndices.value = {
          sectionIndex: null,
          columnIndex: null,
          fieldIndex: null,
          type: null
        };
        await moduleStore.fetchVariables(appId);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return {
    selectedField,
    moduleGraphStore,
    isValidationPropertyVisible,
    confirmDeleteField
  };
};

__sfc_main.components = Object.assign({
  BasicProperties,
  ValidationProperties,
  Conditions
}, __sfc_main.components);
export default __sfc_main;
</script>
