<template>
  <Node 
    :node-id="nodeId"
    class="start-node"
    :show-node-name="false"
    :show-node-options="false"
  />
</template>

<script >
import Node from './Node.vue'; //-- component props --//

const __sfc_main = {};
__sfc_main.props = {
  nodeId: {
    type: String,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  return {};
};

__sfc_main.components = Object.assign({
  Node
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
.start-node {
  .node-content {
    justify-content: center;
  }
  .node-type {
    color: black !important;
  }
}
</style>
