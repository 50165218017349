<template>
  <Connection />
</template>

<script >
import Connection from '@/modules/builder/components/module-sidebar/connection/Connection';
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  return {};
};

__sfc_main.components = Object.assign({
  Connection
}, __sfc_main.components);
export default __sfc_main;
</script>
