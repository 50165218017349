<template>
  <Node 
    :node-id="nodeId"
    :icon="getScreenIcon"
    class="screen-node"
    shape="capsule"
  />
</template>

<script >
// libs
import { computed } from '@vue/composition-api';
import Node from './Node.vue'; // stores

import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore'; //-- use composables --//

const __sfc_main = {};
__sfc_main.props = {
  nodeId: {
    type: String,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const moduleGraphStore = useModuleGraphStore(); //-- component props --//

  const props = __props; // Get screen icon by screen type

  const getScreenIcon = computed(() => {
    const screen = moduleGraphStore.getNodeById(props.nodeId);
    return screen.data.type === 'Sign In' ? 'shield-key' : 'monitor';
  });
  return {
    getScreenIcon
  };
};

__sfc_main.components = Object.assign({
  Node
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';

.screen-node {
  .node-icon {
    background: $yellow;
  }
}
</style>