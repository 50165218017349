<template>
  <div
    class="mt-2"
  >
    <BaseHeading
      tag="h6"
      class="mt-3 mb-1 has-text-weight-bold"
    >
      Conditions:
    </BaseHeading>
    <VueQueryBuilder
      v-model="selectedField.condition"
      :rules="rules"
    >
      <template #default="slotProps">
        <QueryBuilderGroup
          v-bind="slotProps"
          :query.sync="selectedField.condition"
        />
      </template>
    </VueQueryBuilder>
  </div>
</template>

<script >
import { useFormBuilder } from '@/modules/builder/components/form-builder/formBuilder';
import VueQueryBuilder from 'vue-query-builder';
import QueryBuilderGroup from '@/modules/core/components/query-builder/NuclicoreQueryBuilderGroup';
import BaseHeading from '@/modules/core/components/generics/BaseHeading.vue'; //-- composables --//

const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const {
    rules,
    selectedField
  } = useFormBuilder();
  return {
    rules,
    selectedField
  };
};

__sfc_main.components = Object.assign({
  BaseHeading,
  VueQueryBuilder,
  QueryBuilderGroup
}, __sfc_main.components);
export default __sfc_main;
</script>
