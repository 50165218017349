<template>
  <TwitterColorPicker
    v-show="showColorPicker"
    ref="colorPickerElement"
    v-model="selectedTextColor"
    width="auto"
  />
</template>

<script >
import { Twitter as TwitterColorPicker } from 'vue-color';
import { computed, ref } from '@vue/composition-api';
import { onClickOutside } from '@vueuse/core';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: String,
    default: ''
  },
  showColorPicker: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const selectedTextColor = computed({
    get() {
      return props.value || '';
    },

    set(value) {
      emit('input', value.hex);
    }

  });
  const colorPickerElement = ref(null);
  onClickOutside(colorPickerElement, () => {
    if (props.showColorPicker) {
      emit('hide');
    }
  });
  return {
    selectedTextColor,
    colorPickerElement
  };
};

__sfc_main.components = Object.assign({
  TwitterColorPicker
}, __sfc_main.components);
export default __sfc_main;
</script>
