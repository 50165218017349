<template>
  <div>
    <div
      class="mt-2"
    >
      <b-switch
        v-model="basicProperties.hasDynamicChoices"
      >
        Load dynamic choices
      </b-switch>
    </div>

    <div
      v-if="basicProperties.hasDynamicChoices"
    >
      <Editor
        v-model="basicProperties.dynamicChoices"
        :input="true"
        :formula="true"
      />
    </div>
  </div>
</template>

<script >
import Editor from '@/modules/core/components/wysiwyg/Editor';
import { useBasicProperties } from '@/modules/builder/components/form-builder/properties/field-properties/basic-properties/basicProperties';
const __sfc_main = {};
__sfc_main.props = {
  selectedFieldType: {
    type: String,
    default: 'field'
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const {
    basicProperties
  } = useBasicProperties(props.selectedFieldType);
  return {
    basicProperties
  };
};

__sfc_main.components = Object.assign({
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>
