<template>
  <div>
    <div class="mt-2">
      <small>Number of columns in section</small>
      <b-select
        v-model="colNumber"
        expanded
        size="is-small"
        @input="changeColumns()"
      >
        <option value="1">
          1 column
        </option>
        <option value="2">
          2 column
        </option>
        <option value="3">
          3 column
        </option>
      </b-select>
    </div>
    <h2 class="mt-3 mb-1">
      Condition:
    </h2>
    <VueQueryBuilder
      v-model="selectedSectionCondition"
      :rules="rules"
    >
      <template #default="slotProps">
        <QueryBuilderGroup
          v-bind="slotProps"
          :query.sync="selectedSectionCondition"
        />
      </template>
    </VueQueryBuilder>
  </div>
</template>

<script >
import { computed, ref, onMounted } from '@vue/composition-api';
import { useFormBuilder } from '@/modules/builder/components/form-builder/formBuilder';
import VueQueryBuilder from 'vue-query-builder';
import QueryBuilderGroup from '@/modules/core/components/query-builder/NuclicoreQueryBuilderGroup'; //-- composables --//

const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const {
    selectedSection,
    formBuilderInfo,
    selectedFieldIndices,
    rules
  } = useFormBuilder(); //-- query builder logic --//

  const selectedSectionCondition = computed({
    get() {
      return selectedSection.value.condition;
    },

    set(val) {
      formBuilderInfo.value.sections[selectedFieldIndices.value.sectionIndex].condition = val;
    }

  }); //-- columns logic --//

  /** @type {import('@vue/composition-api').Ref<string | null>} */

  const colNumber = ref(null);
  onMounted(() => {
    if (selectedSection.value) {
      colNumber.value = selectedSection.value.columns;
    }
  });

  const changeColumns = () => {
    const section = formBuilderInfo.value.sections[selectedFieldIndices.value.sectionIndex];

    if (colNumber.value > section.columns) {
      if (parseInt(colNumber.value) - parseInt(section.columns) === 1) {
        section.fields.push([{
          type: 'hidden'
        }]);
      } else if (colNumber.value - section.columns === 2) {
        section.fields.push([{
          type: 'hidden'
        }]);
        section.fields.push([{
          type: 'hidden'
        }]);
      }
    } else if (colNumber.value < section.columns) {
      if (parseInt(section.columns) - colNumber.value === 1) {
        if (section.fields[parseInt(section.columns) - 1].length) {
          section.fields[parseInt(section.columns) - 1].forEach(field => {
            if (field.type !== 'hidden') {
              section.fields[0].push(field);
            }
          });
          section.fields.splice(parseInt(section.columns - 1), 1);
        }
      } else if (parseInt(section.columns) - colNumber.value === 2) {
        if (section.fields[parseInt(section.columns) - 2].length) {
          section.fields[parseInt(section.columns) - 1].forEach(field => {
            if (field.type !== 'hidden') {
              section.fields[0].push(field);
            }
          });
          section.fields[parseInt(section.columns) - 2].forEach(field => {
            if (field.type !== 'hidden') {
              section.fields[0].push(field);
            }
          });
          section.fields.splice(parseInt(section.columns - 1), 1);
          section.fields.splice(parseInt(section.columns - 2), 1);
        }
      }
    }

    section.columns = colNumber.value;
  };

  return {
    rules,
    selectedSectionCondition,
    colNumber,
    changeColumns
  };
};

__sfc_main.components = Object.assign({
  VueQueryBuilder,
  QueryBuilderGroup
}, __sfc_main.components);
export default __sfc_main;
</script>
