<template>
  <div>
    <div
      class="mt-2"
    >
      <b-switch
        v-model="basicProperties.hasDefaultValue"
      >
        Has default value
      </b-switch>
    </div>

    <div
      v-if="basicProperties.hasDefaultValue"
      class="mt-2"
    >
      <div 
        class="is-flex is-justify-content-space-between mb-1"
      >
        <BaseLabel class="mt-2">
          Default value
        </BaseLabel>
        <b-switch
          v-if="selectedField.type === 'date'"
          :value="basicProperties.isDefaultValueDynamic"
          @input="toggleDynamicSwitch"
        >
          Dynamic
        </b-switch>
      </div>
      <DefaultAddressInput 
        v-if="selectedField.type === 'address'"
      />
      <b-datepicker
        v-else-if="selectedField.type === 'date' && !basicProperties.isDefaultValueDynamic"
        :value="basicProperties.defaultValue ? new Date(basicProperties.defaultValue) : null"
        @input="updateDefaultDateValue"
      />
      <Editor
        v-else
        v-model="basicProperties.defaultValue"
        :input="true"
      />
      <b-switch
        v-model="basicProperties.persistDefaultValue"
        class="mt-2"
      >
        Persist default value
      </b-switch>
    </div>
  </div>
</template>

<script >
import { useBasicProperties } from '@/modules/builder/components/form-builder/properties/field-properties/basic-properties/basicProperties';
import { useFormBuilder } from '@/modules/builder/components/form-builder/formBuilder';
import Editor from '@/modules/core/components/wysiwyg/Editor';
import BaseLabel from '@/modules/core/components/generics/BaseLabel.vue';
import DefaultAddressInput from './DefaultAddressInput.vue';
const __sfc_main = {};
__sfc_main.props = {
  selectedFieldType: {
    type: String,
    default: 'field'
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const {
    basicProperties
  } = useBasicProperties(props.selectedFieldType);
  const {
    selectedField
  } = useFormBuilder();

  const updateDefaultDateValue = newValue => {
    basicProperties.value.defaultValue = newValue.toISOString();
  };

  const toggleDynamicSwitch = isDynamic => {
    basicProperties.value.defaultValue = '';
    basicProperties.value.isDefaultValueDynamic = isDynamic;
  };

  return {
    basicProperties,
    selectedField,
    updateDefaultDateValue,
    toggleDynamicSwitch
  };
};

__sfc_main.components = Object.assign({
  BaseLabel,
  DefaultAddressInput,
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>
