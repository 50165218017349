<template>
  <div class="node-list-filters columns">
    <BaseSelect
      :value="nodesListFilters.type"
      label="Type"
      class="mb-0"
      :label-props="{ horizontal: true }"
      @input="updateNodeTypeFilter"
    >
      <option :value="null">
        -- Select Node Type --
      </option>
      <option
        v-for="(nodeType, nIndex) in nodeTypes"
        :key="nIndex"
        :value="nodeType"
      >
        {{ nodeType }}
      </option>
    </BaseSelect>
    <BaseSelect
      v-model="nodesListFilters.subType"
      :disabled="!nodesListFilters.type || ['Start', 'End'].includes(nodesListFilters.type)"
      label="Sub Type"
      class="mb-0 mr-5"
      :label-props="{ horizontal: true }"
    >
      <option :value="null">
        -- Select Node Sub-Type --
      </option>
      <option
        v-for="(nodeSubType, nIndex) in nodeSubTypes"
        :key="nIndex"
        :value="nodeSubType"
      >
        {{ nodeSubType }}
      </option>
    </BaseSelect>
    <BaseInput
      v-model="nodesListFilters.name"
      class="mb-0 mr-5"
      label="Name"
      :label-props="{ horizontal: true }"
      expanded
    />
    <b-button
      type="is-danger is-light"
      icon-left="delete"
      class="px-5 rounded-8"
      @click="clearFilters()"
    >
      Reset Filters
    </b-button>
  </div>
</template>

<script >
// libs
import { computed, watch } from '@vue/composition-api'; // components

import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
import BaseInput from '@/modules/core/components/generics/BaseInput.vue'; // stores

import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
import { useModuleStore } from '@/modules/builder/store/moduleStore'; // composables

import { useModuleNodesList } from './moduleNodesList'; // others

import { getDistinctArray, toUpperCaseFirstLetter } from '@/helpers/util';
import { GRAPH_VIEW } from '@/modules/builder/constants/module';
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const {
    nodesListFilters,
    nodesList,
    resetListFilters
  } = useModuleNodesList();
  const moduleGraphStore = useModuleGraphStore();
  const moduleStore = useModuleStore();
  const nodeTypes = computed(() => {
    if (moduleGraphStore.nodes) {
      const nTypes = getDistinctArray(moduleGraphStore.nodes.map(node => node.type));
      return nTypes.map(nType => toUpperCaseFirstLetter(nType));
    }

    return [];
  });
  const nodeSubTypes = computed(() => getDistinctArray(nodesList.value.map(node => node.subType)));

  const updateNodeTypeFilter = type => {
    nodesListFilters.subType = '';
    nodesListFilters.type = type;
  };

  const clearFilters = () => {
    resetListFilters();
  };

  watch(() => moduleStore.moduleView, () => {
    if (moduleStore.moduleView === GRAPH_VIEW) {
      clearFilters();
    }
  });
  return {
    nodesListFilters,
    nodeTypes,
    nodeSubTypes,
    updateNodeTypeFilter,
    clearFilters
  };
};

__sfc_main.components = Object.assign({
  BaseSelect,
  BaseInput
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
.node-list-filters {
  margin-left: 0rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
}
</style>
