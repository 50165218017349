<template>
  <div class="element-list">
    <div
      v-for="(group, groupIndex) in elements"
      :key="groupIndex"
      class="mb-5"
    >
      <BaseHeading
        tag="h4"
        class="group-heading"
      >
        {{ group.name }}
      </BaseHeading>
      <Draggable
        v-model="group.fields"
        :group="{ name: 'elements', pull: 'clone', put: false }"
        :sort="true"
        :clone="cloneElement"
        :move="highlightSection"
        class="columns is-flex-wrap-wrap"
        @end="tryAddingSection"
      >
        <div 
          v-for="(element, fieldIndex) in group.fields"
          :key="fieldIndex"
          class="column"
          :class="group.fields && group.fields.length > 1 ? 'is-6' : 'is-12'"
        >
          <div
            class="draggable-element"
            expanded
          >
            <div
              class="is-flex is-align-items-center"
              :class="{
                'is-flex-direction-column': group.fields && group.fields.length > 1
              }"
            >
              <b-icon
                :icon="element.icon"
                custom-size="mdi-18px"
              />
              <span class="has-text-centered">
                {{ element.label }}
              </span>
            </div>
          </div>
        </div>
      </Draggable>
    </div>
  </div>
</template>

<script >
import { ref, watch } from '@vue/composition-api';
import Draggable from 'vuedraggable';
import { useFormBuilderStore } from '@/modules/builder/store/formBuilderStore';
import { formElementList } from '@/modules/builder/components/form-builder/form-elements/formElements';
import { useDropzone } from '@/modules/builder/components/form-builder/drop-zone/dropZone';
import BaseHeading from '@/modules/core/components/generics/BaseHeading.vue'; // Stores

import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const moduleGraphStore = useModuleGraphStore(); //-- composables --//

  const formBuilderStore = useFormBuilderStore();
  const {
    highlightSection,
    tryAddingSection
  } = useDropzone();
  const allElements = [{
    name: 'Layout',
    fields: formElementList.filter(element => element.category === 'layout')
  }, {
    name: 'Basic elements',
    fields: formElementList.filter(element => element.category === 'basic elements')
  }, {
    name: 'Advanced elements',
    fields: formElementList.filter(element => element.category === 'advance elements')
  }];
  const elements = ref([]);

  const filterFields = (...fieldLabels) => {
    return allElements.map(elementGroup => {
      elementGroup.fields = elementGroup.fields.filter(field => fieldLabels.includes(field.label));
      return elementGroup;
    }).filter(elementGroup => elementGroup.fields.length);
  };

  watch(() => moduleGraphStore.authScreenType, () => {
    if (moduleGraphStore.authScreenType === 'Sign In') {
      elements.value = filterFields('Section', 'Text and images');
    } else {
      elements.value = [...allElements];
    }
  }, {
    immediate: true
  }); //-- clone element logic --//

  const elementList = elements.value.flatMap(el => el.fields);

  const cloneElement = element => {
    const clonedElement = elementList.find(el => el.label === element.label);

    if (clonedElement) {
      return clonedElement.factory();
    }

    return {};
  };

  return {
    highlightSection,
    tryAddingSection,
    elements,
    cloneElement
  };
};

__sfc_main.components = Object.assign({
  BaseHeading,
  Draggable
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
.element-list {
  padding: 0.75rem;
  padding-left: 1.5rem;
  .draggable-element {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    border: none;
    box-shadow: 0 0.125rem 0.25rem rgba(black, 0.1);
    font-family: 'Roboto', Arial, Helvetica;
    font-size: 0.875rem;
    font-weight: 400;
    background: white;
    cursor: pointer;
  }
  .group-heading {
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }
}
</style>
