<template>
  <BaseModal
    v-model="isActive"
    modal-class="form-builder-modal"
    :has-modal-card="true"
    :full-screen="true"
    :can-cancel="false"
  >
    <CardPopup :show-footer="false">
      <template #header>
        <img 
          class="header-logo"
          src="@/assets/logo-plain.svg"
        >
        <p class="modal-card-title">
          Form Builder
        </p>
        <b-button
          label="Close"
          class="is-pulled-right px-5 rounded-8 btn-primary-light"
          size="is-small"
          @click="isActive = false"
        />
      </template>
      <template #body>
        <FormBuilder />
      </template>
    </CardPopup>
  </BaseModal>
</template>

<script >
import { computed } from '@vue/composition-api';
import FormBuilder from './FormBuilder.vue';
import BaseModal from '@/modules/core/components/generics/base-modal/BaseModal.vue';
import CardPopup from '@/modules/core/components/generics/base-modal/CardPopup.vue';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const isActive = computed({
    get() {
      return props.value;
    },

    set(val) {
      emit('input', val);
    }

  });
  return {
    isActive
  };
};

__sfc_main.components = Object.assign({
  BaseModal,
  CardPopup,
  FormBuilder
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';

.form-builder-modal {
  .modal-card-head {
    padding: 12px 20px;
    border-bottom: 1px solid $grey;
    .header-logo {
      margin-right: 0.875rem;
      height: 2rem;
    }
    .modal-card-title {
      margin-bottom: 0;
      font-size: 1.5rem;
      font-family: 'Roboto', Arial;
      font-weight: 700;
    }
  }
  .modal-card-body {
    padding: 0;
    padding-top: 0.75rem;
      .form-builder-content {
        width: 100%;
        .element-column, .property-column {
          padding-bottom: 100px;
          height: 100vh!important;
          overflow-y: auto;
          .group-heading {
            font-size: 1.125rem;
            font-family: 'Roboto', Arial;
            font-weight: 700;
            margin-bottom: 1.5rem;
          }
        }
        .element-column {
          padding: 0;
          background: $grey;
          .element-list {
            padding: 0.75rem;
            padding-left: 1.5rem;
            .draggable-element {
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 0.5rem;
              padding-top: 0.875rem;
              padding-bottom: 0.875rem;
              border: none;
              box-shadow: 0 0.125rem 0.25rem rgba(black, 0.1);
              font-family: 'Roboto', Arial, Helvetica;
              font-size: 0.875rem;
              font-weight: 400;
              background: white;
              cursor: pointer;
            }
          }
        }
    }
  }
}
</style>
