<template>
  <div
    ref="moduleNodesListElement"
    class="nodes-list"
  >
    <div class="is-flex mt-4 ml-3 mb-5">
      <b-button
        class="px-5 rounded-8 btn-primary-light mr-3"
        icon-left="format-list-bulleted-square"
        @click="$emit('close')"
      >
        Close
      </b-button>
      <ListFilters />
    </div>
    <BaseTable
      :data="nodesList"
      :hoverable="true"
      :row-class="row => moduleGraphStore.selectedNodeId === row.nodeId ? 'selected-item' : ''"
      @click="handleListItemSelect"
    >
      <b-table-column
        field="type"
        label="Type"
      >
        <template #default="slotProps">
          <div class="is-flex is-align-items-center">
            <b-icon
              class="mr-2"
              :icon="getNodeTypeIcon(slotProps.row.type)"
            />
            {{ slotProps.row.type }}
          </div>
        </template>
      </b-table-column>

      <b-table-column
        field="subType"
        label="Sub-Type"
      >
        <template #default="slotProps">
          {{ slotProps.row.subType }}
        </template>
      </b-table-column>

      <b-table-column
        field="name"
        label="Name"
      >
        <template #default="slotProps">
          {{ slotProps.row.name }}
        </template>
      </b-table-column>

      <b-table-column
        field="connectionLabels"
        label="Connection Labels"
      >
        <template #default="slotProps">
          <b-tag
            v-for="(connection, cIndex) in slotProps.row.connections"
            :key="cIndex"
            type="is-success"
            class="mr-2"
            @click.stop="handleConnectionSelect(connection)"
          >
            {{ connection.condition.label }}
          </b-tag>
        </template>
      </b-table-column>
    </BaseTable>
  </div>
</template>

<script >
// libs
import { ref } from '@vue/composition-api'; // components

import BaseTable from '@/modules/core/components/generics/BaseTable.vue';
import ListFilters from './ListFilters.vue'; // stores

import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore'; // composables

import { useModuleNodesList } from './moduleNodesList'; // others

const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const moduleGraphStore = useModuleGraphStore();
  const {
    nodesList
  } = useModuleNodesList();
  const moduleNodesListElement = ref(null);
  const nodeIconList = ref([{
    type: 'screen',
    icon: 'monitor'
  }, {
    type: 'action',
    icon: 'view-dashboard-outline'
  }, {
    type: 'integration',
    icon: 'file-code-outline'
  }]);

  const getNodeTypeIcon = type => {
    const iconItem = nodeIconList.value.find(el => el.type === type);
    return iconItem ? iconItem.icon : '';
  }; //-- selection logic --//


  const handleListItemSelect = row => {
    moduleGraphStore.select('node', row.nodeId);
    emit('item-selected');
  };

  const handleConnectionSelect = connection => {
    moduleGraphStore.select('connection', connection.id);
    emit('item-selected');
  };

  return {
    moduleGraphStore,
    nodesList,
    moduleNodesListElement,
    getNodeTypeIcon,
    handleListItemSelect,
    handleConnectionSelect
  };
};

__sfc_main.components = Object.assign({
  ListFilters,
  BaseTable
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';

.nodes-list {
  padding-left: 0.75rem;
  tr {
    cursor: pointer;
  }
  .selected-item {
    background: $grey;
  }
  .table {
    background-color: transparent;
  }
}
</style>
