<template>
  <div class="modal-card">
    <header
      v-if="showHeader"
      class="modal-card-head"
    >
      <slot
        name="header"
      >
        <p class="modal-card-title">
          {{ title }}
        </p>
        <button
          class="delete"
          aria-label="close"
          @click="handleClose()"
        />
      </slot>
    </header>
    <section class="modal-card-body">
      <slot name="body" />
    </section>
    <footer
      v-if="showFooter"
      class="modal-card-foot"
    >
      <slot
        name="footer"
      />
    </footer>
  </div>
</template>

<script >
const __sfc_main = {};
__sfc_main.props = {
  showHeader: {
    type: Boolean,
    default: true
  },
  showFooter: {
    type: Boolean,
    default: true
  },
  title: {
    type: String,
    default: ''
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;

  const handleClose = () => {
    emit('hide');
  };

  return {
    handleClose
  };
};

export default __sfc_main;
</script>

<style lang="scss">
.modal-card {
  .modal-card-head {
    z-index: 999999;
  }
  .delete {
    background: none;
    max-height: 24px;
    max-width: 24px;
    min-height: 24px;
    min-width: 24px;
    width: 24px;
    height: 24px;
    &:before, &:after {
      background: black;
    }
  }
}
</style>
