<template>
  <div>
    <ScreenOptions 
      v-model="formBuilderInfo"
    />
    <BaseSelect
      :value="formBuilderInfo.additionalInfo.database || ''"
      label="Database"
      @input="authMapper.updateSelectedDatabase"
    >
      <option
        v-for="database in databaseOptions.databases"
        :key="'database-'+database.id"
        :value="database.id"
      >
        {{ database.name }}
      </option>
    </BaseSelect>
  
    <BaseSelect
      :value="formBuilderInfo.additionalInfo.table || ''"
      label="Tables"
      @input="authMapper.updateSelectedTable"
    >
      <option
        v-for="(table, tableIdx) in databaseOptions.tables"
        :key="'table-'+tableIdx"
        :value="table.TABLE_NAME"
      >
        {{ table.TABLE_LABEL }}
      </option>
    </BaseSelect>
  
    <BaseSelect
      label="Id Column Type"
      :value="formBuilderInfo.additionalInfo.idColumnType || ''"
      @input="authMapper.swapAuthColumnType($event)"
    >
      <option
        v-for="(idColumn, index) in idColumnTypes"
        :key="index+'-'+idColumn.id"
        :value="idColumn.id"
      >
        {{ idColumn.value }}
      </option>
    </BaseSelect>
  
    <BaseSelect
      v-model="formBuilderInfo.additionalInfo.idColumn"
      label="Id Column"
    >
      <option
        v-for="(column, columnIdx) in databaseOptions.columns"
        :key="'column-'+columnIdx"
        :value="column.column_name"
      >
        {{ column.column_label }}
      </option>
    </BaseSelect>
  
    <BaseSelect
      v-model="formBuilderInfo.additionalInfo.passwordColumn"
      label="Password Column"
    >
      <option
        v-for="(column, columnIdx) in databaseOptions.columns"
        :key="'column-'+columnIdx"
        :value="column.column_name"
      >
        {{ column.column_label }}
      </option>
    </BaseSelect>

    <BaseInput
      v-model="formBuilderInfo.additionalInfo.invalidIdPasswordMessage"
      label="Invalid Id or Password Message"
    />

    <BaseSelect
      v-model="formBuilderInfo.additionalInfo.homeModule"
      label="Home Module"
    >
      <option
        v-for="(mdl, idx) in guardedModules"
        :key="'mdl-'+mdl.id+idx"
        :value="mdl.id"
      >
        {{ mdl.name }}
      </option>
    </BaseSelect>
    <Editor
      v-model="formBuilderInfo.additionalInfo.tokenExpirationTime"
      :formula="false"
      :input="true"
      size="is-large"
      class="w-full mb-4"
    >
      <template #label>
        <BaseLabel class="w-full">
          <div class="is-flex is-justify-content-space-between">
            <span>Token Expiration Time (Days)</span>
            <b-tooltip
              label="Only supports integers, default is 30 days"
              position="is-left"
            >
              <b-icon
                icon="help-circle-outline"
                size="is-small"
              />
            </b-tooltip>
          </div>
        </BaseLabel>
      </template>
    </Editor>
    <b-button
      type="is-primary"
      expanded
      @click="isFormBuilderModalActive = true;"
    >
      <b-icon
        class="mr-1"
        icon="pencil-box-multiple-outline"
      />
      Edit Form
    </b-button>
    <FormBuilderModal 
      v-model="isFormBuilderModalActive"
    />
  </div>
</template>
  
<script >
// libs
import { ref, onMounted, watch } from '@vue/composition-api'; // components

import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
import BaseLabel from '@/modules/core/components/generics/BaseLabel.vue';
import Editor from '@/modules/core/components/wysiwyg/Editor.vue';
import ScreenOptions from '@/modules/builder/components/module-sidebar/screen/shared/ScreenOptions.vue';
import FormBuilderModal from '@/modules/builder/components/form-builder/FormBuilderModal.vue'; // services

import { fetchDatabasesService } from '@/services/database-service/databaseRequests';
import { fetchTablesService } from '@/services/database-service/tableRequests';
import { getColumnsService } from '@/services/database-service/columnRequests'; // composables

import { useRoute } from '@/hooks/vueRouter';
import { useFormBuilder } from '@/modules/builder/components/form-builder/formBuilder';
import { useVariableManager } from '@/modules/builder/components/module-sidebar/screen/shared/screenVariables'; // others

import { createSection, createTextInput, createEmailInput, createPasswordInput, createNextButton } from '@/modules/builder/components/form-builder/form-elements/elementFactories';
import { generateDefaultForm } from '@/modules/builder/components/module-sidebar/screen/shared/screenUtils';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit; // composables specific to this component

  const useAuthMapper = () => {
    const authMapper = {};
    authMapper.databaseOptions = ref({
      databases: [],
      tables: [],
      columns: []
    });

    authMapper.updateSelectedDatabase = databaseId => {
      const matchedDatabase = databaseOptions.value.databases.find(db => db.id === databaseId);

      if (matchedDatabase) {
        formBuilderInfo.value.additionalInfo.database = matchedDatabase.id;
        formBuilderInfo.value.additionalInfo.table = '';
        formBuilderInfo.value.additionalInfo.idColumn = '';
        formBuilderInfo.value.additionalInfo.passwordColumn = '';
        loadTables();
      }
    };

    authMapper.updateSelectedTable = tableName => {
      const matchedTable = databaseOptions.value.tables.find(table => table.TABLE_NAME === tableName);

      if (matchedTable) {
        formBuilderInfo.value.additionalInfo.table = matchedTable.TABLE_NAME;
        formBuilderInfo.value.additionalInfo.idColumn = '';
        formBuilderInfo.value.additionalInfo.passwordColumn = '';
        loadColumns();
      }
    };

    authMapper.swapAuthColumnType = newType => {
      const oldType = formBuilderInfo.value.additionalInfo.idColumnType;
      formBuilderInfo.value.additionalInfo.idColumnType = newType;
      const newField = newType === 'email-input' ? createEmailInput() : createTextInput();
      replaceField(oldType, newField);
    };

    const replaceField = (existingFieldType, newField) => {
      formBuilderInfo.value.sections.forEach(section => {
        section.fields.forEach(row => {
          row.forEach((field, index) => {
            if (field.type === existingFieldType) {
              newField.id = field.id; // conserve existing field id

              row[index] = newField;
            }
          });
        });
      });
    };

    const loadDatabases = async () => {
      try {
        const {
          appId
        } = route.params;
        const {
          data: {
            data
          }
        } = await fetchDatabasesService({
          application_id: appId
        });
        databaseOptions.value.databases = data;

        if (formBuilderInfo.value.additionalInfo.database) {
          loadTables();
        }
      } catch (err) {
        console.error(err);
      }
    };

    const loadTables = async () => {
      try {
        if (formBuilderInfo.value.additionalInfo.database) {
          const {
            data: {
              data
            }
          } = await fetchTablesService(formBuilderInfo.value.additionalInfo.database);
          databaseOptions.value.tables = data;
        }
      } catch (err) {
        console.error(err);
      }
    };

    const loadColumns = async () => {
      try {
        if (formBuilderInfo.value.additionalInfo.database && formBuilderInfo.value.additionalInfo.table) {
          const {
            data: {
              data
            }
          } = await getColumnsService(formBuilderInfo.value.additionalInfo.database, formBuilderInfo.value.additionalInfo.table);
          databaseOptions.value.columns = data;
        }
      } catch (err) {
        console.error(err);
      }
    };

    onMounted(() => {
      loadDatabases();
      loadTables();
      loadColumns();
    });
    return authMapper;
  }; //-- use composables --//


  const route = useRoute();
  const {
    formBuilderInfo
  } = useFormBuilder();
  const variableManager = useVariableManager();
  const authMapper = useAuthMapper();
  const {
    databaseOptions
  } = authMapper; // Id column types

  const idColumnTypes = ref([{
    id: 'text-input',
    value: 'Text'
  }, {
    id: 'email-input',
    value: 'Email'
  }]);
  const guardedModules = ref([{
    id: null,
    name: '-- No Home --'
  }]);
  const isFormBuilderModalActive = ref(false);
  const defaultValue = { ...generateDefaultForm(),
    name: 'Sign In',
    custom_url_name: 'sign-in',
    sections: [createSection([createTextInput(), createPasswordInput(), createNextButton()])],
    additionalInfo: {
      database: '',
      table: '',
      idColumnType: 'text-input',
      idColumn: '',
      passwordColumn: ''
    }
  };

  const loadInfo = () => {
    formBuilderInfo.value = { ...defaultValue,
      ...props.value
    };
  };

  loadInfo();
  watch(() => formBuilderInfo.value, () => {
    emit('input', formBuilderInfo.value);
    variableManager.saveVariables();
  }, {
    deep: true
  });
  return {
    formBuilderInfo,
    authMapper,
    databaseOptions,
    idColumnTypes,
    guardedModules,
    isFormBuilderModalActive
  };
};

__sfc_main.components = Object.assign({
  ScreenOptions,
  BaseSelect,
  BaseInput,
  Editor,
  BaseLabel,
  FormBuilderModal
}, __sfc_main.components);
export default __sfc_main;
</script>
<style lang="scss">
@import '~@/style/utilities.scss';
</style>
