<template>
  <b-dropdown
    ref="variableHeaderDropdown"
    aria-role="list"
    position="is-bottom-left"
    append-to-body
    class="variable-header-dropdown"
    @active-change="updateDropdownToggle"
  >
    <template #trigger>
      <b-icon
        icon="dots-horizontal"
        class="options-icon ml-1"
        :class="{
          active: isDropdownActive
        }"
        @click.native.stop="updateDropdownToggle(!isDropdownActive)"
      />
    </template>
    <b-dropdown-item
      aria-role="listitem"
      @click="notifyMappingAllVariables()"
    >
      <b-icon
        size="is-small"
        class="mr-1"
        icon="vector-polyline-edit"
      />
      <span class="ml-2">Auto Map</span>
    </b-dropdown-item>
  </b-dropdown>
</template>
  
<script >
import { ref } from '@vue/composition-api';
import { variablesBus, ON_MAPPING_VARIABLES } from './variablesTreePopupBus';
const __sfc_main = {};
__sfc_main.props = {
  node: {
    type: Object,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const variableHeaderDropdown = ref(null); //-- dropdown logic --//

  const isDropdownActive = ref(false);

  const updateDropdownToggle = toggleState => {
    isDropdownActive.value = toggleState;
    variableHeaderDropdown.value.isActive = toggleState;
  };

  const notifyMappingAllVariables = () => {
    variablesBus.publish(ON_MAPPING_VARIABLES, props.node);
  };

  return {
    variableHeaderDropdown,
    isDropdownActive,
    updateDropdownToggle,
    notifyMappingAllVariables
  };
};

export default __sfc_main;
</script>

<style lang="scss">
.variable-header-dropdown {
  z-index: 99999;
}
</style>
