<template>
  <div>
    <div
      class="mt-2"
    >
      <b-switch
        v-model="basicProperties.hasPlaceholder"
      >
        Show placeholder
      </b-switch>
    </div>
    <div
      v-if="basicProperties.hasPlaceholder"
      class="mt-2"
    >
      <div 
        class="is-flex is-justify-content-space-between mb-1"
      >
        <BaseLabel class="mt-2">
          Placeholder
        </BaseLabel>
        <b-switch
          v-if="selectedFieldType === 'date'"
          :value="basicProperties.isPlaceholderDynamic"
          @input="toggleDynamicSwitch"
        >
          Dynamic
        </b-switch>
      </div>
      <b-datepicker
        v-if="selectedFieldType === 'date' && !basicProperties.isPlaceholderDynamic"
        :value="basicProperties.placeholder ? new Date(basicProperties.placeholder) : null"
        :date-formatter="formatDate()"
        @input="updatePlaceholderDateValue"
      />
      <Editor
        v-else-if="basicProperties.isPlaceholderDynamic"
        v-model="basicProperties.placeholder"
        :input="true"
      />
      <BaseInput
        v-else
        v-model="basicProperties.placeholder"
      />
    </div>
  </div>
</template>

<script >
import { useBasicProperties } from '@/modules/builder/components/form-builder/properties/field-properties/basic-properties/basicProperties';
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
import BaseLabel from '@/modules/core/components/generics/BaseLabel.vue';
import Editor from '@/modules/core/components/wysiwyg/Editor';
import { set } from '@vue/composition-api';
import moment from 'moment';
const __sfc_main = {};
__sfc_main.props = {
  selectedFieldType: {
    type: String,
    default: 'field'
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const {
    basicProperties
  } = useBasicProperties(props.selectedFieldType);

  const updatePlaceholderDateValue = newValue => {
    basicProperties.value.placeholder = newValue.toISOString();
  };

  const toggleDynamicSwitch = isDynamic => {
    basicProperties.value.placeholder = '';
    set(basicProperties.value, 'isPlaceholderDynamic', isDynamic);
  };

  const formatDate = () => {
    let dateFormat = basicProperties.value.format;

    if (dateFormat) {
      return date => moment(date).format(dateFormat);
    } else {
      return date => moment(date).format('DD.MM.YYYY');
    }
  };

  return {
    basicProperties,
    updatePlaceholderDateValue,
    toggleDynamicSwitch,
    formatDate
  };
};

__sfc_main.components = Object.assign({
  BaseLabel,
  Editor,
  BaseInput
}, __sfc_main.components);
export default __sfc_main;
</script>
