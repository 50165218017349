<template>
  <Node 
    :node-id="nodeId"
    class="end-node"
    :show-footer="false"
    :show-node-name="false"
  />
</template>

<script >
// import { nextTick, onMounted, ref } from '@vue/composition-api';
// import { useNode } from '@/modules/builder/components/module/node/node';
import Node from './Node.vue'; //-- component props --//

const __sfc_main = {};
__sfc_main.props = {
  nodeId: {
    type: String,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  return {};
};

__sfc_main.components = Object.assign({
  Node
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
.end-node {
  .node-content {
    justify-content: center;
  }
  .node-type {
    color: red !important;
  }
}
</style>