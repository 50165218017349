<template>
  <div class="mb-2">
    <!-- number input formatting -->
    <div v-if="fieldType === 'number-input'">
      <BaseSelect
        v-model="basicProperties.format"
        label="Formatting"
        expanded
      >
        <option
          v-for="(formatMask, fIndex) in numberFieldFormatMaskOptions"
          :key="'format-option' + fIndex"
          :value="formatMask.mask"
        >
          {{ formatMask.label }}
        </option>
      </BaseSelect>
      <BaseInput
        v-if="basicProperties.format && basicProperties.format.type === 'custom'"
        v-model="basicProperties.format.delimiter"
        label="Digit group separator"
      />
      <BaseInput
        v-if="basicProperties.format && basicProperties.format.type === 'custom'"
        v-model="basicProperties.format.numeralDecimalMark"
        label="Decimal separator"
      />
      <BaseInput
        v-if="basicProperties.format"
        v-model="basicProperties.format.numeralDecimalScale"
        type="number"
        label="Decimal places"
      />
      <b-switch
        :value="basicProperties.showCurrency"
        class="mb-1"
        @input="toggleCurrencySymbol()"
      >
        Show Currency
      </b-switch>
      <div v-if="basicProperties.showCurrency">
        <b-switch
          v-model="basicProperties.tailPrefix"
          class="mb-1"
        >
          Currency Symbol as Suffix
        </b-switch>
        <BaseSelect
          v-model="basicProperties.currencySymbol"
          label="Currency Symbol"
          expanded
        >
          <option
            v-for="(currencyMask, cIndex) in numberFieldCurrencyMaskOptions"
            :key="'currency-option' + cIndex"
            :value="currencyMask.mask"
          >
            {{ currencyMask.label }}
          </option>
        </BaseSelect>
        <BaseInput
          v-if="basicProperties.currencySymbol && basicProperties.currencySymbol.type === 'custom'"
          v-model="basicProperties.currencySymbol.prefix"
          label="Custom Currency"
        />
      </div>
    </div>
    <!-- date field formatting -->
    <div v-else-if="fieldType === 'date'">
      <BaseSelect
        :value="basicProperties.isCustomDateFormat ? '' : basicProperties.format"
        label="Formatting"
        expanded
        @input="handleDateFormat"
      >
        <option
          v-for="(dateFormat, dIndex) in dateFormatOptions"
          :key="'format-option' + dIndex"
          :value="dateFormat.pattern"
        >
          {{ dateFormat.label }}
        </option>
      </BaseSelect>
      <div
        v-if="basicProperties.isCustomDateFormat"
      >
        <BaseInput
          v-model="basicProperties.format"
          label="Custom Date Format"
          has-tooltip
          :tooltip-props="{
            label: tooltipContent
          }"
        />
      </div>
    </div>
  </div>
</template>

<script >
import { ref, computed, watch } from '@vue/composition-api';
import { useBasicProperties } from '@/modules/builder/components/form-builder/properties/field-properties/basic-properties/basicProperties';
import { useFormBuilder } from '@/modules/builder/components/form-builder/formBuilder';
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
import { useGrid } from './grid/grid';
const __sfc_main = {};
__sfc_main.props = {
  selectedFieldType: {
    type: String,
    default: 'field'
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const {
    basicProperties
  } = useBasicProperties(props.selectedFieldType);
  const {
    selectedField
  } = useFormBuilder();
  const {
    selectedGridCells
  } = useGrid();
  const tooltipContent = `
Format with 'hh': The 'hh' represents the hour in a 12-hour clock format (1-12). Use 'hh' when working with AM/PM time.
Format with 'HH': The 'HH' represents the hour in a 24-hour clock format (0-23). Use 'HH' for 24-hour time representation.
`; //-- number input formatting logic --//

  const numberFieldFormatMaskOptions = ref([{
    label: '. (Period: e.g. 123.456.789,00)',
    mask: {
      numeral: true,
      numeralDecimalMark: ',',
      delimiter: '.',
      numeralDecimalScale: 2
    }
  }, {
    label: ', (Comma: e.g. 123,456,789.00)',
    mask: {
      numeral: true,
      numeralDecimalMark: '.',
      delimiter: ',',
      numeralDecimalScale: 2
    }
  }, {
    label: 'None',
    mask: ''
  }, {
    label: 'Custom',
    mask: {
      type: 'custom',
      numeral: true,
      numeralDecimalMark: ',',
      delimiter: '.',
      numeralDecimalScale: 2
    }
  }]);
  const numberFieldCurrencyMaskOptions = ref([{
    label: 'EUR',
    mask: {
      prefix: '€ ',
      numeral: true,
      numeralDecimalMark: '',
      delimiter: ''
    }
  }, {
    label: 'USD',
    mask: {
      prefix: '$ ',
      numeral: true,
      numeralDecimalMark: '',
      delimiter: ''
    }
  }, {
    label: 'GBP',
    mask: {
      prefix: '£ ',
      numeral: true,
      numeralDecimalMark: '',
      delimiter: ''
    }
  }, {
    label: 'CHF',
    mask: {
      prefix: 'CHf ',
      numeral: true,
      numeralDecimalMark: '',
      delimiter: ''
    }
  }, {
    label: 'None',
    mask: ''
  }, {
    label: 'Custom',
    mask: {
      type: 'custom',
      prefix: '£',
      numeral: true,
      numeralDecimalMark: '',
      delimiter: ''
    }
  }]);

  const toggleCurrencySymbol = () => {
    basicProperties.value.currencySymbol = null;
    basicProperties.value.showCurrency = !basicProperties.value.showCurrency;
  }; //-- date formatting logic --//


  const dateFormatOptions = ref([{
    label: 'DD.MM.YYYY',
    pattern: 'DD.MM.YYYY'
  }, {
    label: 'DD/MM/YYYY',
    pattern: 'DD/MM/YYYY'
  }, {
    label: 'DD.MM.YY',
    pattern: 'DD.MM.YY'
  }, {
    label: 'Custom',
    pattern: ''
  }]);
  const gridCellType = computed(() => {
    const currentGridCell = selectedGridCells.value?.[0];
    return props.selectedFieldType === 'gridCell' ? currentGridCell?.data?.properties?.basic?.element?.type : null;
  });
  const fieldType = computed(() => gridCellType.value || selectedField.value.type);

  const handleDateFormat = selectedDateFormat => {
    basicProperties.value.format = selectedDateFormat;

    if (selectedDateFormat === '') {
      basicProperties.value.isCustomDateFormat = true;
    } else {
      basicProperties.value.isCustomDateFormat = false;
    }
  };
  /**
   * Adds newly introduced properties for existing fields
   */


  const handleOldFieldPropsMigration = () => {
    if (typeof basicProperties.value?.format === 'object' && basicProperties.value.format.numeralDecimalScale === undefined) {
      basicProperties.value.format.numeralDecimalScale = 2;
    }
  };

  watch(() => selectedField.value, () => {
    handleOldFieldPropsMigration();
  });
  return {
    basicProperties,
    tooltipContent,
    numberFieldFormatMaskOptions,
    numberFieldCurrencyMaskOptions,
    toggleCurrencySymbol,
    dateFormatOptions,
    fieldType,
    handleDateFormat
  };
};

__sfc_main.components = Object.assign({
  BaseSelect,
  BaseInput
}, __sfc_main.components);
export default __sfc_main;
</script>
