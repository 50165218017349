<template>
  <div class="dynamic-value-switch">
    <div
      :class="`switch-container ${customSwitchClasses}`"
    >
      <BaseLabel
        v-if="isValueDynamic"
      >
        {{ label }}
      </BaseLabel>
      <slot v-else>
        <b-switch
          v-model="switchValue"
        >
          {{ label }}
        </b-switch>
      </slot>
      <b-switch
        v-model="isValueDynamic"
        :class="dynamicSwitchClasses"
      >
        Dynamic
      </b-switch>
    </div>
    <Editor
      v-if="isValueDynamic"
      v-model="switchValue"
      :input="true"
      :formula="true"
    />
  </div>
</template>

<script >
import { computed } from '@vue/composition-api';
import BaseLabel from '@/modules/core/components/generics/BaseLabel.vue';
import Editor from '@/modules/core/components/wysiwyg/Editor.vue';
const __sfc_main = {};
__sfc_main.props = {
  isDynamic: {
    type: Boolean,
    default: false
  },
  value: {
    type: [Boolean, String],
    default: false
  },
  label: {
    type: String,
    default: ''
  },
  customSwitchClasses: {
    type: String,
    default: ''
  },
  dynamicSwitchClasses: {
    type: String,
    default: ''
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const isValueDynamic = computed({
    get() {
      return props.isDynamic;
    },

    set(value) {
      if (value) {
        switchValue.value = '';
      } else {
        switchValue.value = false;
      }

      emit('update-dynamic-switch', value);
    }

  });
  const switchValue = computed({
    get() {
      return props.value;
    },

    set(value) {
      emit('input', value);
    }

  });
  return {
    isValueDynamic,
    switchValue
  };
};

__sfc_main.components = Object.assign({
  BaseLabel,
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
.dynamic-value-switch {
  .switch-container {
    display: flex;
    justify-content: space-between;
  }
}
</style>
