<template>
  <div>
    <BaseInput
      v-model="dbConnectorInfo.name"
      label="Node name"
    />

    <BaseSelect
      v-model="dbConnectorInfo.databaseType"
      label="Database type"
    >
      <option
        v-for="(type, index) in options.databaseTypes"
        :key="index"
      >
        {{ type }}
      </option>
    </BaseSelect>

    <BaseInput
      v-model="dbConnectorInfo.host"
      label="Host"
    />

    <BaseInput
      v-model="dbConnectorInfo.port"
      type="number"
      label="Port"
    />

    <BaseInput
      v-model="dbConnectorInfo.username"
      label="Username"
    />

    <BaseInput
      v-model="dbConnectorInfo.password"
      type="password"
      label="Password"
    />

    <BaseInput
      v-model="dbConnectorInfo.table"
      label="Table"
    />

    <BaseSelect
      v-model="dbConnectorInfo.action"
      label="Action"
    >
      <option
        v-for="(action, index) in options.actions"
        :key="index"
      >
        {{ action }}
      </option>
    </BaseSelect>
    <div v-if="dbConnectorInfo.action === 'Write'">
      <div
        class="columns"
        style="margin-bottom: 0px;"
      >
        <div class="column is-5">
          Name
        </div>
        <div class="column is-5">
          Value
        </div>
        <div class="column is-2" />
      </div>
      <div
        v-for="(column, key) in dbConnectorInfo.columns"
        :key="key"
        class="columns"
      >
        <div class="column is-5">
          <BaseInput
            v-model="column.name"
            type="text"
            placeholder="e.g. users.first_name"
            variant="list"
          />
        </div>
        <div class="column is-5">
          <Editor
            v-model="column.value"
            :input="true"
          />
        </div>
        <div class="column is-2 sidebar-del-row">
          <b-button @click="deleteColumn(index)">
            <b-icon
              icon="delete-outline"
              type="is-danger"
            />
          </b-button>
        </div>
      </div>
      <b-button
        type="is-primary"
        class="sidebar-add"
        @click="addColumn"
      >
        <b-icon
          icon="plus"
          size="is-small"
          class="mr-2"
          variant="light"
        />
        Add Column
      </b-button>
    </div>
  </div>
</template>

<script >
// libs
import { ref, watch } from '@vue/composition-api'; // components

import Editor from '@/modules/core/components/wysiwyg/Editor';
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const defaultValue = {
    name: 'DBConnector',
    application_id: null,
    module_id: null,
    action: '',
    databaseType: '',
    host: '',
    port: '',
    username: '',
    password: '',
    table: '',
    columns: [{
      name: '',
      value: ''
    }]
  };
  const dbConnectorInfo = ref({});
  const options = ref({
    databaseTypes: ['MySQL/MariaDB', 'PostgreSQL', 'MSSQL', 'Oracle', 'IBM DB2', 'SQLite'],
    actions: ['Read', 'Write']
  });

  const loadInfo = () => {
    dbConnectorInfo.value = { ...defaultValue,
      ...props.value
    };
  };

  loadInfo();
  watch(() => dbConnectorInfo.value, () => {
    emit('input', dbConnectorInfo.value);
  }, {
    deep: true
  });

  const addColumn = () => {
    dbConnectorInfo.value.columns.push({
      name: '',
      value: ''
    });
  };

  const deleteColumn = index => {
    dbConnectorInfo.value.columns.splice(index, 1);
  };

  return {
    dbConnectorInfo,
    options,
    addColumn,
    deleteColumn
  };
};

__sfc_main.components = Object.assign({
  BaseInput,
  BaseSelect,
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
  // Classess prefixed with sidebar-* can be found at ApiConnector
</style>
