<template>
  <div>
    <teleport selector="#moduleEditorHeaderContentRight">
      <div class="is-flex is-align-items-center">
        <b-button
          class="px-5 rounded-8 btn-primary-light mr-3"
          size="is-small"
          @click="tryDeploy"
        >
          Deploy
        </b-button>
        <b-tooltip
          v-if="!hiddenHeaderOptions.includes(moduleHeaderOptions.MODULE_EDITOR_VIEW)"
          :label="moduleStore.moduleView === 'LIST' ? 'Show Graph' : 'Show List'"
          type="is-primary"
          position="is-bottom"
        >
          <div class="mr-3 cursor-pointer">
            <b-icon
              :icon="moduleStore.moduleView === 'LIST' ? 'graph' : 'format-list-bulleted-square'"
              @click.native="toggleModuleView()"
            />
          </div>
        </b-tooltip>
        <b-tooltip
          v-if="!hiddenHeaderOptions.includes(moduleHeaderOptions.VARIABLES)"
          label="Variables"
          type="is-primary"
          position="is-bottom"
        >
          <div class="mr-3 cursor-pointer">
            <b-icon
              icon="at"
              @click.native="isVariableModalActive = true;"
            />
          </div>
        </b-tooltip>
        <b-tooltip
          v-if="!hiddenHeaderOptions.includes(moduleHeaderOptions.WORKFLOWS)"
          label="Workflows"
          type="is-primary"
          position="is-bottom"
        >
          <div class="mr-3 cursor-pointer workflow-icon">
            <b-icon
              icon="sitemap"
              class="icon"
              @click.native="setWorkflowModalVisibility(true)"
            />
            <span
              v-if="workflowStore.noOfWorkflows"
              class="badge"
            >
              {{ workflowStore.noOfWorkflows }}
            </span>
          </div>
        </b-tooltip>
        <b-tooltip
          v-if="!hiddenHeaderOptions.includes(moduleHeaderOptions.VERSIONS)"
          label="Versions"
          type="is-primary"
          position="is-bottom"
        >
          <div class="mr-3 cursor-pointer">
            <b-icon
              icon="history"
              @click.native="openModuleVersions()"
            />
          </div>
        </b-tooltip>
        <b-tooltip
          v-if="!hiddenHeaderOptions.includes(moduleHeaderOptions.PREVIEW)"
          label="Preview"
          type="is-primary"
          position="is-bottom"
        >
          <div class="mr-3 cursor-pointer">
            <b-icon
              icon="play-circle-outline"
              @click.native="runApp()"
            />
          </div>
        </b-tooltip>
        <b-tooltip
          :label="showSidebar ? 'Hide Sidebar' : 'Show Sidebar'"
          type="is-primary"
          position="is-bottom"
        >
          <div class="mr-3 cursor-pointer">
            <b-icon
              :icon="showSidebar ? 'chevron-right' : 'chevron-left'"
              @click.native="showSidebar = !showSidebar"
            />
          </div>
        </b-tooltip>
      </div>
    </teleport>
    <b-notification
      v-if="isModuleLocked"
      style="position: fixed; width: 100%; z-index: 999999; bottom: -24px;"
      :closable="false"
      type="is-warning"
    >
      <p>
        This module is locked because another user ({{ lockedUser }}) is currently editing it.
        <b-button
          size="is-small"
          class="is-pulled-right"
          type="is-dark"
          @click="takeOver()"
        >
          Take over and start editing
        </b-button>
      </p>
    </b-notification>
    <div class="columns h-full">
      <div
        class="column"
        :class="[
          showSidebar ? 'main-section-small' : 'main-section-large',
          moduleStore.moduleView === 'GRAPH' ? 'px-3 py-5 has-light-bg' : 'p-0'
        ]"
        :style="{
          width: `calc(100% - ${elementWidth}px)`
        }"
      >
        <ModuleGraph
          v-if="isModuleFetched"
          v-show="moduleStore.moduleView === 'GRAPH'"
          class="h-full position-relative"
          @item-selected="itemSelected($event)"
        />
        <ModuleNodesList 
          v-show="moduleStore.moduleView === 'LIST'"
          @item-selected="showSidebar = true;"
          @close="toggleModuleView()"
        />
      </div>
  
      <div
        class="column has-insert-shadow overflow-auto sidebar-wrapper"
        :class="showSidebar ? 'sidebar-section-large' : 'sidebar-section-small'"
        :style="{
          width: `${elementWidth}px`
        }"
      >
        <template v-if="showSidebar">
          <div
            class="sidebar-resizer"
            @mousedown.stop="handleSidebarSize($event)"
          >
            <div class="sidebar-drag-handle" />
          </div>
          <ModuleSidebar />
        </template>
      </div>
  
      <!-- modals -->
      <SelectDeploymentEnvModal
        v-model="isSelectDeploymentEnvModalActive"
        :module-type-id="typeId"
        @select-deployment="openDeploymentModal"
      />
      <DeploymentModal
        v-model="isDeploymentModalActive"
        :module-type-id="typeId"
        :themes="options.themes"
      />
      <VariablesListModal
        v-model="isVariableModalActive"
        :app-name="appName"
      />
      <WorkflowListModal 
        v-model="isWorkflowModalActive"
      />
    </div>
  </div>
</template>
  
<script >
// libs
import { computed, onMounted, onBeforeUnmount, ref, watch } from '@vue/composition-api';
import { debouncedWatch } from '@vueuse/core'; // components

import ModuleGraph from '@/modules/builder/components/module-graph/ModuleGraph.vue';
import ModuleSidebar from '@/modules/builder/components/module-sidebar/ModuleSidebar.vue';
import VariablesListModal from '@/modules/builder/components/module-modals/VariablesListModal.vue';
import DeploymentModal from '@/modules/builder/components/module-modals/DeploymentModal.vue';
import SelectDeploymentEnvModal from '@/modules/builder/components/module-modals/SelectDeploymentEnvModal.vue';
import ModuleNodesList from '@/modules/builder/components/module-nodes-list/ModuleNodesList.vue';
import WorkflowListModal from '@/modules/builder/components/module-workflows/WorkflowListModal.vue'; // services

import { deleteVariableWithNodeService } from '@/services/application-service/variableRequests';
import { updateModuleService } from '@/services/application-service/moduleRequests';
import { fetchThemeService } from '@/services/build-service/themeRequests'; // stores

import { useApplicationStore } from '@/modules/builder/store/applicationStore';
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
import { useFormBuilderStore } from '@/modules/builder/store/formBuilderStore';
import { useModuleStore } from '@/modules/builder/store/moduleStore';
import { useModuleDeployStore } from '@/modules/builder/store/moduleDeployStore';
import { useWorkflowStore } from '@/modules/builder/store/workflowStore'; // composables

import { useRoute, useRouter } from '@/hooks/vueRouter';
import { useSocket } from '@/hooks/vueSocket';
import { useSession } from '@/hooks/vueSession'; // others

import { moduleEventBus, ON_NODE_REMOVED } from '@/modules/builder/common/moduleEventBus';
import { GRAPH_VIEW, LIST_VIEW } from '@/modules/builder/constants/module';
import { headerOptions } from './moduleBuilderConstants';
import { useResizable } from '@/modules/core/components/generics/elementResize';
import { throttle } from 'lodash';
const __sfc_main = {};
__sfc_main.props = {
  hiddenHeaderOptions: {
    type: Array,
    default: () => []
  },
  additionalVariablesModuleIds: {
    type: Array,
    default: () => []
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props; //-- use composables --//

  const applicationStore = useApplicationStore();
  const formBuilderStore = useFormBuilderStore();
  const moduleStore = useModuleStore();
  const moduleGraphStore = useModuleGraphStore();
  const moduleDeployStore = useModuleDeployStore();
  const route = useRoute();
  const router = useRouter();
  const socket = useSocket();
  const session = useSession();
  const workflowStore = useWorkflowStore();
  const {
    elementWidth,
    handleResize
  } = useResizable();
  const moduleHeaderOptions = ref({ ...headerOptions
  });
  const handleSidebarSize = throttle(evt => handleResize(evt), 100); //-- module deploy logic --//

  const isSelectDeploymentEnvModalActive = ref(false);
  const isDeploymentModalActive = ref(false);

  const tryDeploy = () => {
    isSelectDeploymentEnvModalActive.value = true;
  };

  const openDeploymentModal = () => {
    isDeploymentModalActive.value = true;
  }; //-- module variables logic --//


  const isVariableModalActive = ref(false); //-- module view logic --//

  const showSidebar = ref(true);

  const toggleModuleView = () => {
    if (moduleStore.moduleView === LIST_VIEW) {
      moduleStore.moduleView = GRAPH_VIEW;
      showSidebar.value = true;
    } else {
      moduleStore.moduleView = LIST_VIEW;
      showSidebar.value = false;
    }
  };

  const itemSelected = event => {
    showSidebar.value = true;

    if (event && event?.condition) {
      updateLabels();
    }
  };

  const updateLabels = () => {
    const sourceNode = moduleGraphStore.getNodeByConnectionId(moduleGraphStore.selectedConnectionId);

    if (sourceNode) {
      moduleGraphStore.updateConnectionsLabel(sourceNode.connections);
    }
  }; //-- themes logic --//


  const options = ref({
    themes: []
  });

  const getThemes = async () => {
    const {
      data: {
        data
      }
    } = await fetchThemeService();
    options.value.themes = data;
  }; //-- module CRUD logic --//


  const isModuleFetched = ref(false);
  const lockedUser = computed(() => moduleStore.moduleDetails?.is_locked ? moduleStore.moduleDetails.locked_user : '');
  const isModuleLocked = computed(() => !!lockedUser.value);
  const appName = computed(() => moduleStore.moduleDetails?.name || '');
  const typeId = computed(() => moduleStore.moduleDetails?.type || 0);

  const fetchModule = async () => {
    try {
      const {
        appId
      } = route.params;
      await Promise.all([moduleStore.fetchVariables(appId), moduleStore.fetchEnvironmentVariables(appId), moduleStore.fetchModule(appId, moduleStore.moduleId)]);
      await moduleDeployStore.getEnvironments(moduleStore.moduleId, typeId.value); // fetch auth module variable, if it's guarded

      if (moduleStore?.moduleDetails?.guarded_module_id) {
        await moduleStore.fetchAuthModuleVariables(appId, moduleStore.moduleDetails.guarded_module_id);
      }

      isModuleFetched.value = true;
    } catch (err) {
      console.error(err);
    }
  };

  const updateModule = async () => {
    try {
      const {
        appId
      } = route.params;
      await moduleStore.updateModule(appId, moduleStore.moduleId);
    } catch (err) {
      console.error(err);
    }
  };

  debouncedWatch(() => moduleGraphStore.nodes, async () => {
    try {
      await updateModule();
    } catch (err) {
      console.error(err);
    }
  }, {
    deep: true,
    debounce: 500
  });

  const takeOver = async () => {
    const {
      appId
    } = route.params;
    await updateModuleService(appId, moduleStore.moduleId, {}, '?take_over=true');
    await fetchModule();
  };

  const initEditor = () => {
    socket.client.emit('lock', {
      module_id: moduleStore.moduleId,
      user_id: session.get('id')
    });
    moduleEventBus.subscribe(ON_NODE_REMOVED, handleNodeDeletion);
  };

  const handleNodeDeletion = async deletedNodeId => {
    try {
      const {
        appId
      } = route.params;
      await deleteVariableWithNodeService(appId, moduleStore.moduleId, {
        node_id: deletedNodeId.id
      });
      await moduleStore.fetchVariables(appId);
      await updateModule();
    } catch (err) {
      console.error(err);
    }
  }; //-- workflow logic --//


  const isWorkflowModalActive = ref(false);

  const setWorkflowModalVisibility = visibility => {
    isWorkflowModalActive.value = visibility;
  }; //-- miscellaneous --//


  const removeSocket = () => {
    socket.client.emit('unlock', {
      module_id: moduleStore.moduleId,
      user_id: session.get('id')
    });
  };

  const runApp = () => {
    router.push('/preview/application/' + route.params.appId + '/module/' + moduleStore.moduleId);
  };

  const openModuleVersions = () => {
    router.push('/application/' + route.params.appId + '/module/' + moduleStore.moduleId + '/versions');
  };

  watch(() => moduleStore.moduleId, async () => {
    if (moduleStore.moduleId) {
      await fetchModule();
      getThemes();
    }
  }, {
    deep: true
  });
  onMounted(async () => {
    applicationStore.reset();
    formBuilderStore.reset();
    moduleStore.reset();
    initEditor();

    if (moduleStore.moduleId) {
      await fetchModule();
      getThemes();
    }

    const {
      appId,
      moduleId
    } = route.params;
    await workflowStore.fetchWorkflows(appId, moduleId);
  });
  onBeforeUnmount(() => {
    removeSocket();
    moduleGraphStore.reset();
  });
  return {
    moduleStore,
    workflowStore,
    elementWidth,
    moduleHeaderOptions,
    handleSidebarSize,
    isSelectDeploymentEnvModalActive,
    isDeploymentModalActive,
    tryDeploy,
    openDeploymentModal,
    isVariableModalActive,
    showSidebar,
    toggleModuleView,
    itemSelected,
    options,
    isModuleFetched,
    lockedUser,
    isModuleLocked,
    appName,
    typeId,
    takeOver,
    isWorkflowModalActive,
    setWorkflowModalVisibility,
    runApp,
    openModuleVersions
  };
};

__sfc_main.components = Object.assign({
  ModuleGraph,
  ModuleNodesList,
  ModuleSidebar,
  SelectDeploymentEnvModal,
  DeploymentModal,
  VariablesListModal,
  WorkflowListModal
}, __sfc_main.components);
export default __sfc_main;
</script>
  
<style lang="scss">
@import '~@/style/components.scss';
@import '~@/style/variables.scss';
  
  .has-light-bg {
    background: #ededed !important;
  }
  
  .has-insert-shadow {
    -webkit-box-shadow: -10px 9px 15px -6px rgba(0, 0, 0, 0.1);
    box-shadow: -10px 9px 15px -6px rgba(0, 0, 0, 0.1);
  }
  
  .main-section-large {
    flex: none;
    width: 100% !important;
    transition-property: width;
    transition: 0.4s ease;
  }
  
  .main-section-small {
    flex: none;
    width: 70%;
    transition-property: width;
    transition: 0.4s ease;
  }

  .sidebar-wrapper {
    position: relative;
    z-index: 30;
    padding-bottom: 12.5rem;
  }
  
  .sidebar-section-large {
    flex: none;
    width: 30%;
  }
  
  .sidebar-section-small {
    flex: none;
    width: 0 !important;
  }

  .sidebar-resizer {
    height: 100vh;
    width: 0.5rem;
    position: absolute;
    left: 0;
    top: 0;
    cursor: ew-resize;
    .sidebar-drag-handle {
      height: 100vh;
      width: 0.25rem;
      transition: all 0.3s ease-out;
    }
    &:hover {
      .sidebar-drag-handle {
        background: $primary;
      }
    }
  }

  .workflow-icon {
    position: relative;
    display: inline-block;

    .icon {
        font-size: 24px;
    }

    .badge {
        position: absolute;
        top: -10px;
        right: -8px;
        background: grey;
        color: white;
        border-radius: 50%;
        padding: 1px 6px;
        font-size: 10px;
    }
  }
</style>
  