<template>
  <Draggable
    :list="fields"
    :empty-insert-threshold="200"
    group="elements"
    style="min-height: 200px;"
    :move="highlightSection"
    @end="tryAddingSection"
  >
    <SectionField 
      v-for="(field, fieldIndex) in fields"
      :key="fieldIndex"
      :field="field"
      @click.native="updateSelectedField(fieldIndex, field.type)"
    />
  </Draggable>
</template>

<script >
import Draggable from 'vuedraggable';
import { useDropzone } from '@/modules/builder/components/form-builder/drop-zone/dropZone';
import SectionField from './SectionField.vue';
import { useFormBuilder } from '@/modules/builder/components/form-builder/formBuilder';
const __sfc_main = {};
__sfc_main.props = {
  fields: {
    type: Array,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  //-- composables --//
  const {
    highlightSection,
    tryAddingSection
  } = useDropzone();
  const {
    selectedFieldIndices
  } = useFormBuilder();

  const updateSelectedField = (fieldIndex, fieldType) => {
    selectedFieldIndices.value.fieldIndex = fieldIndex;
    selectedFieldIndices.value.type = fieldType;
  };

  return {
    highlightSection,
    tryAddingSection,
    updateSelectedField
  };
};

__sfc_main.components = Object.assign({
  Draggable,
  SectionField
}, __sfc_main.components);
export default __sfc_main;
</script>
