<template>
  <div class="is-clipped">
    <div
      v-for="(row,rowIndex) in tilesView"
      :key="`row_${rowIndex}`"
      :class="`is-flex ${props.field.properties.basic.tilesAlignment} m-4`"
    >
      <div
        v-for="tile in row"
        :key="`tile_${tile.id}`"
      > 
        <div
          class="box is-flex mx-2 tile"
          :class="{'is-active': selectedTileId === tile.id, 'is-flex-direction-column': imageFirst(tile), 'is-flex-direction-column-reverse': !imageFirst(tile) && tile.label}"
          :style="tileStyles"
          @click="selectTile(tile.id)"
        >
          <img
            :src="tileImage(tile)"
            class="is-block mx-auto"
            :class="{
              'mt-3': !imageFirst(tile) && tile.label,
              'mt-4': !tile.label
            }"
            :style="tileImageStyles"
          >
          <p
            v-if="tile.label"
            class="is-size-7 has-text-weight-bold has-text-centered text-overflow-ellipsis"
            :class="{
              'mt-3': imageFirst(tile)
            }"
          >
            {{ tile.label }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script >
import { ref, onMounted, computed } from '@vue/composition-api';
import { debouncedWatch } from '@vueuse/core';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const tilesView = ref([]);
  const selectedTileId = ref(null);
  const tileStyles = computed(() => {
    const size = props.field?.properties?.basic?.size;
    return {
      cursor: 'pointer',
      width: size ? size + 'px' : '7.5rem',
      height: size ? size + 'px' : '7.5rem'
    };
  });
  const tileImageStyles = computed(() => {
    const imageSize = props.field?.properties?.basic?.imageSize;
    return {
      width: imageSize ? imageSize + 'px' : '3.125rem',
      height: imageSize ? imageSize + 'px' : '3.125rem'
    };
  });

  const imageFirst = tile => {
    const contentArrangement = props.field?.properties?.basic?.contentArrangement;
    return contentArrangement === 'text-first' ? false : contentArrangement === 'image-first' ? true : tile.arrangeImageFirst;
  };

  const selectTile = id => {
    if (selectedTileId.value === id) {
      selectedTileId.value = null;
    } else {
      selectedTileId.value = id;
    }
  };

  const tileImage = file => {
    const image = file?.file_upload ? file.image : file.image_url;
    return image ?? 'https://bulma.io/images/placeholders/480x480.png';
  };

  const processTiles = () => {
    tilesView.value = [];
    const numberOfRows = props.field?.properties?.basic?.numberOfRows || 0;
    const numberOfCols = props.field?.properties?.basic?.numberOfCols || 0;
    let tileIndex = 0;

    for (let row = 0; row < numberOfRows; row++) {
      const currentRow = [];

      for (let col = 0; col < numberOfCols; col++) {
        currentRow.push(props.field?.properties?.basic?.tiles?.[tileIndex]);
        tileIndex++;
      }

      tilesView.value.push(currentRow);
    }
  };

  onMounted(() => {
    processTiles();
  });
  debouncedWatch(() => [props.field?.properties?.basic?.numberOfRows, props.field?.properties?.basic?.numberOfCols], () => {
    processTiles();
  }, {
    deep: true,
    debounce: 500
  });
  return {
    props,
    tilesView,
    selectedTileId,
    tileStyles,
    tileImageStyles,
    imageFirst,
    selectTile,
    tileImage
  };
};

export default __sfc_main;
</script>

<style lang="scss" scoped>
@import '~@/style/utilities.scss';
.is-active {
    box-shadow: 0 0.5em 1em -0.125em #5550FC, 0 0px 0 1px #5550FC;
}
.tile:hover {
  background-color: #f0f0f0; /* Set the desired background color on hover */
  opacity: 0.7; 
  box-shadow: 0 0.5em 1em -0.125em #5550FC, 0 0px 0 1px #5550FC;
}
</style>
  