<template>
  <BaseModal
    v-model="isModalVisible"
    :has-modal-card="true"
    :trap-focus="true"
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-label="Image Source"
    aria-modal
  >
    <CardPopup
      title="Image Source"
      class="w-600"
      @hide="reset()"
    >
      <template #body>
        <div class="is-flex is-justify-content-flex-end">
          <b-switch
            v-model="isFileUpload"
          >
            File Upload
          </b-switch>
        </div>
        <template
          v-if="isFileUpload"
        >
          <b-field
            class="mt-4"
          >
            <b-upload
              v-model="files"
              accept="image/*"
              class="is-block w-full"
              multiple
              drag-drop
            >
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon
                      icon="upload"
                      size="is-large"
                    />
                  </p>
                  <p>Drop your file here or click to upload</p>
                </div>
              </section>
            </b-upload>
          </b-field>
          <div>
            <b-field>
              <b-tag
                v-for="(file, fIndex) in files"
                :key="'image-upload' + fIndex"
                type="is-primary"
                closable
                class="mb-2 mr-2"
                @close="removeFileFromSelection(fIndex)"
              >
                {{ file.name }}
              </b-tag>
            </b-field>
          </div>
        </template>
        <template
          v-else
        >
          <BaseLabel>
            Enter URL
          </BaseLabel>
          <Editor
            v-model="url"
            class="mb-4"
            :input="true"
            :formula="true"
          />
        </template>
      </template>
      <template #footer>
        <div class="is-flex is-justify-content-space-between w-full">
          <b-button
            class="px-6 rounded-8 btn-primary-light"
            @click="reset()"
          >
            Cancel
          </b-button>
          <b-button
            :disabled="isFileUpload ? !files.length : !url"
            :loading="isUploading"
            type="is-primary"
            class="px-6 rounded-8"
            @click="handleUpdate()"
          >
            OK
          </b-button>
        </div>
      </template>
    </CardPopup>
  </BaseModal>
</template>
  
<script >
// libs
import { computed, defineAsyncComponent, ref } from '@vue/composition-api';
import axios from 'axios'; // components

import BaseModal from '@/modules/core/components/generics/base-modal/BaseModal.vue';
import CardPopup from '@/modules/core/components/generics/base-modal/CardPopup.vue';
import BaseLabel from '@/modules/core/components/generics/BaseLabel.vue';
const Editor = defineAsyncComponent(() => import('./Editor.vue'));
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Boolean,
    default: false
  },
  initialUrl: {
    type: String,
    default: ''
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const url = ref('');
  const isFileUpload = ref(false);
  const files = ref([]);
  const isUploading = ref(false);
  const isModalVisible = computed({
    get() {
      return props.value;
    },

    set(val) {
      emit('input', val);
    }

  });

  const removeFileFromSelection = index => {
    files.value.splice(index, 1);
  };

  const handleUpdate = async () => {
    try {
      let emittedValue = [];

      if (isFileUpload.value) {
        await uploadFiles();
        emittedValue = files.value.map(file => `${process.env.VUE_APP_APP_SERVICE_DOMAIN}/${file.name}`);
      } else {
        emittedValue = [url.value];
      }

      emit('update', emittedValue);
      reset();
    } catch (err) {
      console.error(err);
    }
  };

  const reset = () => {
    url.value = '';
    files.value = [];
    isModalVisible.value = false;
  };

  const uploadFiles = async () => {
    try {
      isUploading.value = true;
      const formData = new FormData();
      files.value.forEach(file => {
        let fileName = file.name.split('.');
        fileName = fileName.slice(0, fileName.length).join('.');
        formData.append(fileName, file);
      });
      await axios({
        method: 'POST',
        url: process.env.VUE_APP_APP_SERVICE_DOMAIN + '/upload',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    } finally {
      isUploading.value = false;
    }
  };

  return {
    url,
    isFileUpload,
    files,
    isUploading,
    isModalVisible,
    removeFileFromSelection,
    handleUpdate,
    reset
  };
};

__sfc_main.components = Object.assign({
  BaseModal,
  CardPopup,
  BaseLabel,
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>
