<template>
  <BaseModal
    v-model="isUpdateVariableModalActive"
    :has-modal-card="true"
    :trap-focus="true"
    aria-role="dialog"
    aria-label="Update Variables"
    aria-modal
  >
    <CardPopup
      :title="`${variable ? 'Update' : 'Add'} Variable`"
      class="w-800"
      @hide="isUpdateVariableModalActive = false"
    >
      <template #body>
        <div class="columns">
          <div class="column is-3">
            Type
          </div>
          <div class="column is-4">
            Name
          </div>
          <div
            v-if="globalVar.type !== 'URL parameter'"
            class="column is-4"
          >
            Value
          </div>
          <div class="column is-1" />
        </div>
        <div class="columns">
          <b-field class="column is-3">
            <b-select
              v-model="globalVar.type"
              required
              expanded
              size="is-small"
            >
              <option value="URL parameter">
                URL parameter
              </option>
              <option value="In app variable">
                In app variable
              </option>
            </b-select>
          </b-field>
          <b-field class="column is-4">
            <b-input
              v-model="globalVar.name"
              type="text"
              size="is-small"
              required
              expanded
              validation-message="Name is required"
            />
          </b-field>
          <div
            v-if="globalVar.type !== 'URL parameter'"
            class="column is-4"
          >
            <b-input
              v-model="globalVar.value"
              size="is-small"
              expanded
            />
          </div>
        </div>
      </template>
      <template #footer>
        <div class="is-flex is-justify-content-space-between w-full">
          <b-button
            class="px-6 rounded-8 btn-primary-light"
            @click="isUpdateVariableModalActive = false"
          >
            Cancel
          </b-button>
          <b-button
            type="is-primary"
            class="px-6 rounded-8"
            @click="saveVariable()"
          >
            {{ variable ? 'Update' : 'Add' }}
          </b-button>
        </div>
      </template>
    </CardPopup>
  </BaseModal>
</template>

<script >
// libs
import { computed, ref, watch } from '@vue/composition-api';
import { uuid } from 'vue-uuid'; // components

import BaseModal from '@/modules/core/components/generics/base-modal/BaseModal.vue';
import CardPopup from '@/modules/core/components/generics/base-modal/CardPopup.vue'; // stores

import { useModuleStore } from '@/modules/builder/store/moduleStore';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Boolean,
    default: false,
    description: '2-way binded value for modal\'s visibility'
  },
  variable: {
    type: Object,
    default: null,
    description: 'initialize variable values for editing'
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit; //-- use composables --//

  const moduleStore = useModuleStore(); //-- 2-way binding --//

  const isUpdateVariableModalActive = computed({
    get() {
      return props.value;
    },

    set(val) {
      emit('input', val);
    }

  }); //-- update variable logic --//

  const defaultGlobalVar = {
    type: '',
    name: '',
    value: null,
    reference: uuid.v4(),
    node_id: null,
    module_id: null
  };
  const globalVar = ref({ ...defaultGlobalVar
  });
  watch(() => isUpdateVariableModalActive.value, () => {
    if (isUpdateVariableModalActive.value) {
      if (props.variable) {
        globalVar.value = { ...props.variable,
          name: props.variable.name.split('>').slice(-1)[0].trim()
        };
      }
    } else {
      const {
        moduleId
      } = moduleStore;
      globalVar.value = { ...defaultGlobalVar,
        reference: uuid.v4(),
        module_id: moduleId
      };
    }
  });

  const saveVariable = () => {
    if (globalVar.value.name) {
      emit('save', { ...globalVar.value
      });
      isUpdateVariableModalActive.value = false;
    }
  };

  return {
    isUpdateVariableModalActive,
    globalVar,
    saveVariable
  };
};

__sfc_main.components = Object.assign({
  BaseModal,
  CardPopup
}, __sfc_main.components);
export default __sfc_main;
</script>
