<template>
  <component
    :is="renderedField.component"
    v-if="renderedField"
    :field="field"
  />
</template>

<script >
import { computed, ref } from '@vue/composition-api';
import * as Field from '@/modules/builder/components/form-builder/drop-zone/field-components/fieldComponents';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const renderedField = computed(() => fieldTypes.value.find(field => field.type === props.field.type));
  /** @type {import('@vue/composition-api').Ref<import('./types/field').IFieldTypes[]>} */

  const fieldTypes = ref([{
    type: 'address',
    component: Field.AddressInput
  }, {
    type: 'checkbox',
    component: Field.Checkbox
  }, {
    type: 'data-table',
    component: Field.DataTable
  }, {
    type: 'date',
    component: Field.DatePicker
  }, {
    type: 'document',
    component: Field.Document
  }, {
    type: 'dropdown',
    component: Field.Dropdown
  }, {
    type: 'e-signature',
    component: Field.ESignature
  }, {
    type: 'email-input',
    component: Field.EmailInput
  }, {
    type: 'file-upload',
    component: Field.FileUpload
  }, {
    type: 'html-editor',
    component: Field.HtmlEditor
  }, {
    type: 'iban',
    component: Field.IBanInput
  }, {
    type: 'number-input',
    component: Field.NumberInput
  }, {
    type: 'password',
    component: Field.PasswordInput
  }, {
    type: 'text',
    component: Field.PlainText
  }, {
    type: 'time',
    component: Field.TimePicker
  }, {
    type: 'radio-box',
    component: Field.RadioBox
  }, {
    type: 'toc',
    component: Field.TermsConditionsInput
  }, {
    type: 'text-area',
    component: Field.TextArea
  }, {
    type: 'text-input',
    component: Field.TextInput
  }, {
    type: 'tariff-comparison',
    component: Field.TariffComparision
  }, {
    type: 'iframe',
    component: Field.IFrameField
  }, {
    type: 'payment-gateway',
    component: Field.PaymentGateway
  }, {
    type: 'grid',
    component: Field.Grid
  }, {
    type: 'tile',
    component: Field.Tile
  }]);
  return {
    renderedField
  };
};

export default __sfc_main;
</script>
