<template>
  <div>
    <small>Glue character:</small>
    <b-select
      v-model="formulaInfo.data.glue"
      size="is-small"
      expanded
    >
      <option
        v-for="(glue, index) in glues"
        :key="index"
      >
        {{ glue }}
      </option>
    </b-select>
    <div
      v-if="formulaInfo.data.glue === 'Custom'"
      class="my-3"
    >
      <small>Custom glue character(s)</small>
      <editor
        v-model="formulaInfo.data.customSeparator"
        :input="true"
        :formula="true"
      />
    </div>
    <div
      v-for="(instance, index) in formulaInfo.data.instances"
      :key="index"
    >
      <small>Text</small>
      <div>
        <editor
          v-model="instance.value"
          :input="true"
          :formula="true"
        />
      </div>
    </div>
    <b-button
      type="is-primary"
      size="is-small"
      expanded
      class="mt-3"
      @click="addString"
    >
      Add new string
    </b-button>
  </div>
</template>

<script >
import { defineAsyncComponent, onMounted, ref } from '@vue/composition-api';
import { debouncedWatch } from '@vueuse/core';
import lodash from 'lodash';
const Editor = defineAsyncComponent(() => import('@/modules/core/components/wysiwyg/Editor'));
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const formulaInfo = ref({
    formula: 'CONCATENATE',
    data: {
      glue: '',
      customSeparator: '',
      instances: [{
        value: ''
      }, {
        value: ''
      }]
    }
  });
  const glues = ref(['Space', 'Comma', 'Custom']);

  const addString = () => {
    formulaInfo.value.data.instances.push({
      value: ''
    });
  };

  let avoidSavingFormula = false;
  onMounted(() => {
    if (Object.keys(props.value?.data || {}).length) {
      avoidSavingFormula = true;
      formulaInfo.value = lodash.cloneDeep(props.value);
    }
  });
  debouncedWatch(() => formulaInfo.value, () => {
    if (avoidSavingFormula) {
      avoidSavingFormula = false;
    } else {
      emit('input', formulaInfo.value);
    }
  }, {
    deep: true,
    debounce: 500
  });
  return {
    formulaInfo,
    glues,
    addString
  };
};

__sfc_main.components = Object.assign({
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>
