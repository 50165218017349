<template>
  <div>
    <b-upload
      disabled
      size="is-small"
      expanded
      :accept="acceptedFormats"
    >
      <span class="file-cta">
        <b-icon
          class="file-icon"
          size="is-small"
          icon="upload"
        />
        <span class="file-label">{{ field.properties.basic.label }}</span>
      </span>
    </b-upload>
  </div>
</template>

<script >
import { computed } from '@vue/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const acceptedFormats = computed(() => {
    return !props.field.properties.validation.allowAllFormats && props.field.properties.validation.allowed?.length ? props.field.properties.validation.allowed.join(',') : '';
  });
  return {
    acceptedFormats
  };
};

export default __sfc_main;
</script>
