<template>
  <div>
    <ScreenOptions
      v-model="formBuilderInfo"
    />

    <b-button
      type="is-primary"
      expanded
      @click="isFormBuilderModalActive = true;"
    >
      <b-icon
        class="mr-1"
        icon="pencil-box-multiple-outline"
      />
      Edit Form
    </b-button>
    <FormBuilderModal
      v-model="isFormBuilderModalActive"
    />
  </div>
</template>

<script >
// libs
import { ref, watch } from '@vue/composition-api'; // components

import ScreenOptions from '@/modules/builder/components/module-sidebar/screen/shared/ScreenOptions.vue';
import FormBuilderModal from '@/modules/builder/components/form-builder/FormBuilderModal.vue'; // stores

import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore'; // composables

import { useFormBuilder } from '@/modules/builder/components/form-builder/formBuilder';
import { useVariableManager } from '@/modules/builder/components/module-sidebar/screen/shared/screenVariables';
import { generateDefaultForm } from '@/modules/builder/components/module-sidebar/screen/shared/screenUtils';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit; //-- use composables --//

  const moduleGraphStore = useModuleGraphStore();
  const {
    formBuilderInfo,
    selectedFieldIndices
  } = useFormBuilder();
  const variableManager = useVariableManager();
  const isFormBuilderModalActive = ref(false);

  const loadInfo = () => {
    formBuilderInfo.value = { ...generateDefaultForm(),
      ...props.value,
      custom_url_name: props.value?.custom_url_name || `Form-${moduleGraphStore.screenNodes.length + 1}`
    };
    selectedFieldIndices.value = {
      sectionIndex: null,
      columnIndex: null,
      fieldIndex: null,
      type: null
    };
  };

  loadInfo();
  watch(() => formBuilderInfo.value, () => {
    emit('input', formBuilderInfo.value);
    variableManager.saveVariables();
  }, {
    deep: true
  });
  return {
    formBuilderInfo,
    isFormBuilderModalActive
  };
};

__sfc_main.components = Object.assign({
  ScreenOptions,
  FormBuilderModal
}, __sfc_main.components);
export default __sfc_main;
</script>
