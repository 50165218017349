<template>
  <div
    v-if="basicProperties.tariffs !== undefined"
    class="mt-2"
  >
    <BaseLabel class="mt-3">
      Tariffs:
    </BaseLabel>
    <b-collapse
      v-for="(tariff, index) in basicProperties.tariffs"
      :key="'tariff' + index"
      class="card mb-2" 
      animation="slide" 
      :aria-id="'tariff-collapse' + index"
    >
      <template #trigger="slotProps">
        <div
          class="card-header"
          role="button"
          :aria-controls="'tariff-collapse' + index"
          :aria-expanded="slotProps.open"
        >
          <p
            class="card-header-title"
          >
            {{ 'Tariff ' + (index + 1) }}
          </p>
          <a class="card-header-icon">
            <b-icon
              :icon="slotProps.open ? 'menu-down' : 'menu-up'"
            />
          </a>
        </div>
      </template>

      <div class="card-content">
        <div class="content">
          <div>
            <div class="is-flex is-justify-content-space-between mt-3">
              <BaseLabel
                v-if="tariff.isVisibilityDynamic"
                class="mt-2"
              >
                Show Tariff
              </BaseLabel>
              <b-switch
                v-else
                v-model="tariff.isVisible"
              >
                Show Tariff
              </b-switch>
              <b-switch
                :value="tariff.isVisibilityDynamic"
                @input="makeTariffVisibilityDynamic($event, tariff)"
              >
                Dynamic
              </b-switch>
            </div>
            <Editor
              v-if="tariff.isVisibilityDynamic"
              v-model="tariff.isVisible"
              :input="true"
              :formula="true"
            />
            <div class="is-flex is-justify-content-space-between mt-3">
              <BaseLabel
                v-if="tariff.isButtonVisibilityDynamic"
                class="mt-2"
              >
                Show Tariff Button
              </BaseLabel>
              <b-switch
                v-else
                v-model="tariff.isButtonVisible"
              >
                Show Tariff Button
              </b-switch>
              <b-switch
                v-model="tariff.isButtonVisibilityDynamic"
              >
                Dynamic
              </b-switch>
            </div>
            <Editor
              v-if="tariff.isButtonVisibilityDynamic"
              v-model="tariff.isButtonVisible"
              :input="true"
              :formula="true"
            />
            <BaseLabel class="mt-2">
              Highlight Tariff
            </BaseLabel>
            <Editor
              v-model="tariff.isHighlighted"
              :input="true"
              :formula="true"
            />
            <BaseLabel class="mt-2">
              Title
            </BaseLabel>
            <Editor
              v-model="tariff.title"
              :input="true"
              :formula="true"
            />
            <BaseLabel class="mt-2">
              <div class="is-flex is-align-items-center">
                Premium
              </div>
            </BaseLabel>
            <Editor
              v-model="tariff.price"
              :input="true"
              :formula="true"
            />
            <BaseLabel class="mt-2">
              Description
            </BaseLabel>
            <Editor
              v-model="tariff.description"
              :formula="true"
            />
          </div>
        </div>
      </div>
    </b-collapse>
    <BaseLabel class="mt-2">
      Highlighted Tariff Text
    </BaseLabel>
    <Editor
      v-model="basicProperties.highlightedTariffText"
      :input="true"
      :formula="true"
    />
    <BaseLabel class="mt-3">
      <div class="is-flex is-align-items-center">
        Tariff Selection Button:
        <b-tooltip
          type="is-dark"
          multilined
          position="is-right"
          size="is-small"
        >
          <b-icon
            icon="help-circle-outline"
            custom-size="mdi-18px"
          />
          <template #content>
            <div>
              Button will not be shown for tariffs&nbsp;
              if title and premium is evaluated to be empty
            </div>
          </template>
        </b-tooltip>
      </div>
    </BaseLabel>
    <div>
      <BaseInput
        v-model="basicProperties.tariffSelectionButton.text"
        class="mt-2"
        label="Text"
      />
    </div>
  </div>
</template>

<script >
import { onMounted, set } from '@vue/composition-api';
import { useBasicProperties } from '@/modules/builder/components/form-builder/properties/field-properties/basic-properties/basicProperties';
import Editor from '@/modules/core/components/wysiwyg/Editor.vue';
import BaseLabel from '@/modules/core/components/generics/BaseLabel.vue';
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const {
    basicProperties
  } = useBasicProperties();
  onMounted(() => {
    if (basicProperties.value?.tariffs?.length) {
      basicProperties.value.tariffs.forEach(tariff => {
        if (tariff.isVisible === undefined) {
          set(tariff, 'isVisible', true);
        }

        if (tariff.isButtonVisible === undefined) {
          set(tariff, 'isButtonVisible', true);
        }
      });
    }
  });

  const makeTariffVisibilityDynamic = (isDynamic, tariff) => {
    if (isDynamic) {
      tariff.isVisible = '';
    } else {
      tariff.isVisible = true;
    }

    if (tariff.isVisibilityDynamic === undefined) {
      set(tariff, 'isVisibilityDynamic', isDynamic);
    } else {
      tariff.isVisibilityDynamic = isDynamic;
    }
  };

  return {
    basicProperties,
    makeTariffVisibilityDynamic
  };
};

__sfc_main.components = Object.assign({
  BaseLabel,
  Editor,
  BaseInput
}, __sfc_main.components);
export default __sfc_main;
</script>
