<template>
  <b-button
    size="sm"
    type="is-danger"
    icon-right="close"
    class="delete-connection-btn"
    @click.stop="handleDelete()"
  />
</template>

<script >
import { removeConnection } from '@/modules/core/components/generics/base-tree-flow/treeFlowUtils';
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
const __sfc_main = {};
__sfc_main.props = {
  connectionId: {
    type: String,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const moduleGraphStore = useModuleGraphStore();

  const handleDelete = () => {
    const sourceNode = moduleGraphStore.getNodeByConnectionId(props.connectionId);
    removeConnection(moduleGraphStore.nodes, props.connectionId);
    moduleGraphStore.updateConnectionsLabel(sourceNode.connections);
  };

  return {
    handleDelete
  };
};

export default __sfc_main;
</script>

<style lang="scss">
.delete-connection-btn {
  z-index: 50;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  i {
    &:before {
      font-size: 1rem !important;
    }
  }
}
</style>
