<template>
  <div
    class="base-select"
    :class="variant"
  >
    <b-field
      v-bind="labelProps"
      :label="label"
      :custom-class="`base-label ${labelProps.customClass || 'default'}`"
    >
      <b-select
        v-bind="$attrs"
        v-model="baseValue"
      >
        <slot />
      </b-select>
    </b-field>
  </div>
</template>

<script >
import { computed } from '@vue/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: [String, Number, Boolean, Array, Object],
    default: null
  },
  variant: {
    type: String,
    default: 'default'
  },
  label: {
    type: String,
    default: ''
  },
  labelProps: {
    type: Object,
    default: () => ({})
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const baseValue = computed({
    get() {
      return props.value;
    },

    set(value) {
      emit('input', value);
    }

  });
  return {
    baseValue
  };
};

export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';
@import '~@/modules/core/components/generics/styles/label.scss';

.base-select {
  .is-horizontal {
    align-items: center;
    .base-label {
      margin-bottom: 0;
      flex-grow: 2;
    }
  }

  &.default {
    margin-bottom: 10px;

    .select {
      border-radius: 2px;
      font-size: 0.75rem;
      width: 100%;
    }
    select {
      border: 1px solid $grey-9 !important;
      border-radius: 8px !important;
      font-weight: 400;
      font-size: 13px !important;
      color: $dark !important;
      padding: 0px 14px !important;
      width: 100%;
    }
    select:focus {
      box-shadow: none !important;
    }
    .select::after {
      border-color: $dark !important;
      margin-top: 0 !important;
      width: 9px !important;
      height: 9px !important;
    }
  }

  &.list {
    margin-bottom: 0px;

    .select {
      border-radius: 2px;
      font-size: 0.75rem;
      width: 100%;
    }
    select {
      border: 1px solid $grey-9 !important;
      border-radius: 8px !important;
      font-weight: 400;
      font-size: 13px !important;
      line-height: 20px !important;
      color: $dark !important;
      padding: 0px 14px !important;
      width: 100%;
    }
    select:focus {
      box-shadow: none !important;
    }
    .select::after {
      border-color: $dark !important;
      margin-top: 0 !important;
      width: 9px !important;
      height: 9px !important;
    }
  }

  /** Override bulma styling */ 
  // .label:not(:last-child) {
  //   margin-bottom: 6px !important;
  // }
}
</style>