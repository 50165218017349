<template>
  <div>
    <div>
      <b-select
        v-model="formulaName"
        size="is-small"
        expanded
      >
        <option
          v-for="(option, index) in formulas"
          :key="index"
          :value="option.name"
        >
          {{ option.name }}
        </option>
      </b-select>
    </div>
    <template v-if="formulaInfo">
      <component
        :is="selectedFormula.component"
        v-model="formulaInfo"
      />
    </template>
  </div>
</template>

<script >
// libs
import { ref, computed } from '@vue/composition-api'; // components

import Concatenate from '@/modules/core/components/formula-builder/formulas/Concatenate';
import IfCondition from '@/modules/core/components/formula-builder/formulas/IfCondition';
import Now from '@/modules/core/components/formula-builder/formulas/Now';
import Upper from '@/modules/core/components/formula-builder/formulas/Upper';
import TimezoneConverter from '@/modules/core/components/formula-builder/formulas/TimezoneConverter';
import Lower from '@/modules/core/components/formula-builder/formulas/Lower';
import ArithmeticCalculation from '@/modules/core/components/formula-builder/formulas/ArithmeticCalculation';
import Left from '@/modules/core/components/formula-builder/formulas/Left';
import Right from '@/modules/core/components/formula-builder/formulas/Right';
import MultipleConditionResult from '@/modules/core/components/formula-builder/formulas/MultipleConditionResult';
import DateArithmeticCalculation from '@/modules/core/components/formula-builder/formulas/DateArithmeticCalculation';
import ArrayFilter from '@/modules/core/components/formula-builder/formulas/ArrayFilter';
import Base64Encoder from '@/modules/core/components/formula-builder/formulas/Base64Encoder';
import NumberFormatter from '@/modules/core/components/formula-builder/formulas/NumberFormatter';
import ManipulateDate from '@/modules/core/components/formula-builder/formulas/ManipulateDate.vue';
import AccessProperty from '@/modules/core/components/formula-builder/formulas/AccessProperty.vue';
import MapArray from '@/modules/core/components/formula-builder/formulas/MapArray.vue';
import DateFormatter from '@/modules/core/components/formula-builder/formulas/DateFormatter.vue';
import InsertFormattedText from '@/modules/core/components/formula-builder/formulas/InsertFormattedText.vue';
import CombineExpressions from '@/modules/core/components/formula-builder/formulas/CombineExpressions.vue';
import SortArray from '@/modules/core/components/formula-builder/formulas/SortArray.vue';
import GenerateFileLink from '@/modules/core/components/formula-builder/formulas/GenerateFileLink.vue';
import ReduceArray from '@/modules/core/components/formula-builder/formulas/ReduceArray.vue';
import SearchArray from '@/modules/core/components/formula-builder/formulas/SearchArray.vue';
import SplitText from '@/modules/core/components/formula-builder/formulas/SplitText.vue';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    default: null
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const formulaInfo = computed({
    get() {
      return props.value;
    },

    set(value) {
      emit('input', value);
    }

  });
  const formulaName = computed({
    get() {
      return props.value?.formula || '';
    },

    set(value) {
      formulaInfo.value = {
        formula: value,
        data: {}
      };
    }

  });
  const selectedFormula = computed(() => formulas.value.find(formulaItem => formulaItem.name === formulaName.value));
  const formulas = ref([{
    name: 'ACCESS PROPERTY',
    component: AccessProperty
  }, {
    name: 'ARITHMETIC CALCULATION',
    component: ArithmeticCalculation
  }, {
    name: 'COMBINE EXPRESSIONS',
    component: CombineExpressions
  }, {
    name: 'CONCATENATE',
    component: Concatenate
  }, {
    name: 'CONVERT DATE TIMEZONE',
    component: TimezoneConverter
  }, {
    name: 'DATE ARITHMETICS',
    component: DateArithmeticCalculation
  }, {
    name: 'ENCODE TO BASE64',
    component: Base64Encoder
  }, {
    name: 'FILTER ARRAY',
    component: ArrayFilter
  }, {
    name: 'FORMAT DATE',
    component: DateFormatter
  }, {
    name: 'FORMAT NUMBER',
    component: NumberFormatter
  }, {
    name: 'GENERATE FILE LINK',
    component: GenerateFileLink
  }, {
    name: 'IF CONDITION IS TRUE',
    component: IfCondition
  }, {
    name: 'INSERT FORMATTED TEXT',
    component: InsertFormattedText
  }, {
    name: 'LEFT',
    component: Left
  }, {
    name: 'MANIPULATE DATE',
    component: ManipulateDate
  }, {
    name: 'MAP ARRAY',
    component: MapArray
  }, {
    name: 'MULTIPLE CONDITION RESULT',
    component: MultipleConditionResult
  }, {
    name: 'NOW',
    component: Now
  }, {
    name: 'RIGHT',
    component: Right
  }, {
    name: 'SEARCH ARRAY',
    component: SearchArray
  }, {
    name: 'SPLIT TEXT',
    component: SplitText
  }, {
    name: 'SORT ARRAY',
    component: SortArray
  }, {
    name: 'GENERATE FILE LINK',
    component: GenerateFileLink
  }, {
    name: 'REDUCE ARRAY',
    component: ReduceArray
  }, {
    name: 'TO LOWER',
    component: Lower
  }, {
    name: 'TO UPPER',
    component: Upper
  }]);
  return {
    formulaInfo,
    formulaName,
    selectedFormula,
    formulas
  };
};

export default __sfc_main;
</script>
