<template>
  <div>
    <b-datetimepicker
      ref="dateTimePickerElement"
      v-model="datetime"
      size="is-small"
      expanded
      editable
      locale="de-DE"
      :disabled="field.properties.basic.isDisabled"
      :placeholder="field.properties.basic.hasPlaceholder
        ? dateTimeFormatter.formatDateTime(field.properties.basic.placeholder)
        : ''
      "
      :datetime-formatter="dateTimeFormatter.formatDateTime"
      :datetime-parser="dateTimeFormatter.parseDateTime"
      :timepicker="{
        hourFormat,
        disabled: !field.properties.basic.hasTimePicker,
      }"
      :mobile-native="false"
      horizontal-time-picker
    >
      <template #left>
        <b-button
          label="Jetzt"
          type="is-primary"
          icon-left="clock"
          @click="selectCurrentDateTime"
        />
      </template>
      <template #right>
        <div class="is-flex is-flex-direction-column is-align-items-center">
          <b-button
            label="Löschen"
            type="is-danger"
            icon-left="close"
            outlined
            @click="clearDateTime"
          />
          <b-button
            v-if="field.properties.basic.hasTimePicker"
            label="Ok"
            type="is-primary"
            icon-left="check"
            class="mt-5"
            @click="dateTimePickerElement.toggle()"
          />
        </div>
      </template>
    </b-datetimepicker>
  </div>
</template>
  
<script >
// libs
import { computed, ref, onMounted } from '@vue/composition-api';
import moment from 'moment-timezone';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const datePickerFormat = computed(() => props.field.properties.basic?.format || 'DD.MM.YYYY'); // composables specific to this component

  const useDateTimeFormatter = () => {
    const dateTimeFormatter = {};
    dateTimeFormatter.hourFormat = computed(() => datePickerFormat.value?.includes('h') ? '12' : '24');
    /**
       * @param {Date} date 
       */

    dateTimeFormatter.formatDateTime = date => {
      const userTimeZone = moment.tz.guess();
      const localTime = moment.tz(date, userTimeZone);
      return localTime.format(datePickerFormat.value);
    };
    /**
       * @param {string} dateString 
       */


    dateTimeFormatter.parseDateTime = dateString => {
      const userTimeZone = moment.tz.guess();
      let momentObject = moment.tz(dateString, datePickerFormat.value, userTimeZone);

      if (!props.field.properties.basic.hasTimePicker) {
        momentObject = momentObject?.startOf('day');
      }

      return momentObject.toDate();
    };

    return dateTimeFormatter;
  };

  const useDateTimePicker = () => {
    const dateTimePicker = {};
    dateTimePicker.dateTimePickerElement = ref(null); // template ref

    const toggleTimePickerVisibility = () => {
      const timePickerElement = dateTimePicker.dateTimePickerElement.value?.$el.querySelector('.timepicker');

      if (timePickerElement) {
        if (props.field.properties.basic.hasTimePicker) {
          timePickerElement.style.display = 'block';
        } else {
          timePickerElement.style.display = 'none';
        }
      }
    };

    onMounted(() => {
      toggleTimePickerVisibility();
    });
    return dateTimePicker;
  }; // use composables


  const dateTimeFormatter = useDateTimeFormatter();
  const dateTimePicker = useDateTimePicker(); // unwrap nested refs and computed (so that script setup can unwrap inner value to template)

  const {
    hourFormat
  } = dateTimeFormatter;
  const {
    dateTimePickerElement
  } = dateTimePicker;
  const datetime = ref(null);

  const selectCurrentDateTime = () => {
    let currentDate = moment();

    if (!props.field.properties.basic.hasTimePicker) {
      currentDate = currentDate.startOf('day');
    }

    datetime.value = currentDate.toDate();
    dateTimePickerElement.value.toggle();
  };

  const clearDateTime = () => {
    datetime.value = null;
  };

  return {
    dateTimeFormatter,
    hourFormat,
    dateTimePickerElement,
    datetime,
    selectCurrentDateTime,
    clearDateTime
  };
};

export default __sfc_main;
</script>

<style lang="scss">
.datepicker-footer{
    .level{
        display: flex;
        flex-direction: column;
        .level-item {
            margin-right: 0 !important;
        }
    }
    button {
        width: fit-content;
    }
}
</style>
