<template>
  <div>
    <small>Value to evaluate</small>
    <editor
      v-model="formulaInfo.data.value"
      :input="true"
      :formula="true"
    />
    <div
      v-for="(option, index) in formulaInfo.data.options"
      :key="index"
      class="mt-2 p-2"
      style="border: 1px solid #dedede"
    >
      <small>If value equals to:</small>
      <editor
        v-model="option.case"
        :input="true"
        :formula="true"
      />
      <small>Then result will be:</small>
      <editor
        v-model="option.value"
        :input="true"
        :formula="true"
      />
    </div>
    <b-button
      class="my-2"
      type="is-primary"
      size="is-small"
      expanded
      @click="addCondition"
    >
      Add new formulaInfo
    </b-button>
    <b-switch
      v-model="formulaInfo.data.hasDefault"
      class="my-2"
    >
      Have a value in case all conditions are false
    </b-switch>
    <editor
      v-if="formulaInfo.data.hasDefault"
      v-model="formulaInfo.data.defaultValue"
      :input="true"
      :formula="true"
    />
  </div>
</template>

<script >
import { defineAsyncComponent, onMounted, ref } from '@vue/composition-api';
import { debouncedWatch } from '@vueuse/core';
import lodash from 'lodash';
const Editor = defineAsyncComponent(() => import('@/modules/core/components/wysiwyg/Editor'));
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const formulaInfo = ref({
    formula: 'MULTIPLE CONDITION RESULT',
    data: {
      value: '',
      defaultValue: '',
      hasDefault: true,
      options: [{
        case: '',
        value: ''
      }]
    }
  });

  const addCondition = () => {
    formulaInfo.value.data.options.push({
      case: '',
      value: ''
    });
  };

  let avoidSavingFormula = false;
  onMounted(() => {
    if (Object.keys(props.value?.data || {}).length) {
      avoidSavingFormula = true;
      formulaInfo.value = lodash.cloneDeep(props.value);
    }
  });
  debouncedWatch(() => formulaInfo.value, () => {
    if (avoidSavingFormula) {
      avoidSavingFormula = false;
    } else {
      emit('input', formulaInfo.value);
    }
  }, {
    deep: true,
    debounce: 500
  });
  return {
    formulaInfo,
    addCondition
  };
};

__sfc_main.components = Object.assign({
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>