<template>
  <div>
    <b-radio
      v-for="(choice, cIndex) in field.properties.basic.choices"
      :key="cIndex"
      v-model="selectedValue"
      class="is-12"
      :class="!field.properties.basic.displayOptionsInRow ? 'column' : ''"
      size="is-small"
      :native-value="choice"
      @click.native="handleInput(choice,selectedValue)"
    >
      {{ choice }}
    </b-radio>
  </div>
</template>

<script >
import { ref } from '@vue/composition-api';
import { debounce } from 'lodash';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const selectedValue = ref();
  const handleInput = debounce((newValue, oldValue) => {
    if (newValue === oldValue) {
      selectedValue.value = '';
    }
  }, 300);
  return {
    selectedValue,
    handleInput
  };
};

export default __sfc_main;
</script>
