<template>
  <div>
    <div
      v-if="loading"
      class="columns is-vcentered px-5"
    >
      <div class="is-10 column">
        <b-skeleton height="30" />
        <b-skeleton height="50" />
      </div>
      <div class="is-2 column">
        <b-skeleton height="30" />
      </div>
    </div>

    <NoResults
      v-else-if="tableData.length === 0"
      :banner-label="noResultsContent.bannerLabel"
      :button-label="noResultsContent.buttonLabel"
      :button-handler="() => handleTableAction(tableActions.CREATE)"
    />

    <b-table
      v-else
      :data="tableData"
      class="tab-table-layout"
      @click="handleTableAction(tableActions.EDIT, $event)"
    >
      <b-table-column
        v-slot="{ row }"
        label="Name"
        field="name"
        width="30%"
      >
        {{ row.name }}
      </b-table-column>
      <b-table-column
        v-slot="{ row }"
        label="Modified"
        field="updated_at"
      >
        {{ row.updated_at }}
        <b-tooltip
          v-if="row.last_editor" 
          :label="row.last_editor"
        >
          <b-button
            size="is-small" 
            class="button disk disk-small is-primary"
          >
            {{ row.last_editor_initials }}
          </b-button>
        </b-tooltip>
      </b-table-column>
      <b-table-column
        field="row.test_version"
        label="TEST"
      >
        <template
          #header="{ column }"
        >
          <b-tooltip
            label="Version deployed and running on test"
            append-to-body
            dashed
          >
            {{ column.label }}
          </b-tooltip>
        </template>

        <template #default="{ row }">
          {{ row.test_version }}
        </template>
      </b-table-column>

      <b-table-column
        label="Production"
        field="prod_deployed_version"
      >
        <template
          #header="{ column }"
        >
          <b-tooltip
            label="Version deployed and running on production"
            append-to-body
            dashed
          >
            {{ column.label }}
          </b-tooltip>
        </template>
        <template
          #default="{ row }"
        >
          {{ row.prod_version }}
        </template>
      </b-table-column>
      <b-table-column
        v-slot="{ row }"
        label="Type"
        field="type"
      >
        {{ row.type }}
        <b-icon
          :icon="iconMapper(row)"
          size="is-size-6"
          class="app-dropdown-icon"
        />
      </b-table-column>
      <b-table-column
        v-slot="{ row }"
        label=""
        cell-class="has-text-right"
      >
        <b-button
          size="is-small"
          class="app-dropdown-btn"
          @click.stop="toggleOptionsDropdown(row)"
        >
          <b-icon
            icon="dots-horizontal"
            size="is-size-6"
            class="app-dropdown-icon"
          />
        </b-button>
        <ModuleOptionsDropdown
          v-if="row.dropdown.isLoaded"
          v-model="row.dropdown.isVisible"
          :module-info="row"
          @select="handleTableAction($event, row)"
        />
      </b-table-column>
    </b-table>
    <ModuleInfoSidebar 
      v-model="isSidebarVisible"
      :module-info="sidebarInfo"
      @guard-module="updateGuardStatus($event, 'guard')"
      @unguard-module="updateGuardStatus($event, 'unguard')"
    />
    <DeleteModuleModal 
      v-model="isDeleteModuleModalVisible"
      :module-info="moduleToBeDeleted"
      @delete="deleteModule"
    />
  </div>
</template>

<script >
// lib
import { computed, ref } from '@vue/composition-api'; // components

import ModuleOptionsDropdown from './ModuleOptionsDropdown.vue';
import ModuleInfoSidebar from './ModuleInfoSidebar.vue';
import DeleteModuleModal from './DeleteModuleModal.vue';
import NoResults from '@/modules/core/components/NoResults.vue'; // stores

import { useWorkflowStore } from '@/modules/builder/store/workflowStore'; // services

import { copyModuleService, deleteModuleService, fetchModuleByIdService } from '@/services/application-service/moduleRequests'; // composables

import { useRoute } from '@/hooks/vueRouter';
import { useBuefy } from '@/hooks/buefy';
import { useModuleTable } from './moduleTable'; // others

import { delay } from '@/helpers/util';
const __sfc_main = {};
__sfc_main.props = {
  iconMapper: {
    type: Function,
    default: () => ''
  },
  modules: {
    type: Array,
    required: true
  },
  loading: {
    type: Boolean,
    default: true
  },
  noResultsContent: {
    type: Object,
    default: () => ({
      bannerLabel: 'No modules yet, create one now.',
      buttonLabel: 'New Module'
    })
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit; //-- use composables --//

  const route = useRoute();
  const buefy = useBuefy();
  const {
    tableActions
  } = useModuleTable();
  const workflowStore = useWorkflowStore();
  const isDropdownVisible = ref(false);
  const isSidebarVisible = ref(false);
  const isDeleteModuleModalVisible = ref(false);
  const sidebarInfo = ref({});
  const moduleToBeDeleted = ref({});
  const tableData = computed(() => {
    return props.modules.map(module => ({ ...module,
      dropdown: {
        isVisible: false,
        isLoaded: false // lazy loads dropdown (since loading all dropdowns at once creates performance issues)

      }
    }));
  });

  const setSidebarVisibility = visibility => {
    isSidebarVisible.value = visibility;
  };

  const openSidebar = moduleInfo => {
    setSidebarVisibility(true);
    sidebarInfo.value = { ...moduleInfo
    };
  };

  const toggleOptionsDropdown = async tableRow => {
    // close all other dropdowns
    tableData.value.forEach(module => {
      if (module.id !== tableRow.id) {
        module.dropdown.isVisible = false;
      }
    });
    tableRow.dropdown.isLoaded = true;
    await delay(0);
    tableRow.dropdown.isVisible = !tableRow.dropdown.isVisible;
  };

  const handleTableAction = (action, selectedRow) => {
    const {
      INFO,
      DUPLICATE,
      DELETE
    } = tableActions.value;
    const actionHandlers = {
      [INFO]: openSidebar,
      [DUPLICATE]: handleCopyingModule,
      [DELETE]: confirmDelete
    };
    const handler = actionHandlers[action];

    if (handler) {
      handler(selectedRow);
    } else {
      delegateAction(action, selectedRow);
    }
  };
  /**
   * @param {string} tableAction
   * @param {import('@/modules/builder/components/application/types/modulesList').IModuleItem | null} selectedRow
   */


  const delegateAction = (tableAction, selectedRow = null) => {
    emit('delegate-action', {
      tableAction,
      selectedRow
    });
  };
  /**
   * @param {import('@/modules/builder/components/application/types/modulesList').IModuleItem} moduleInfo
   */


  const handleCopyingModule = async moduleInfo => {
    try {
      const {
        id
      } = moduleInfo;
      const {
        data: {
          data
        }
      } = await fetchModuleByIdService(route.params.appId, id);
      buefy.dialog.prompt({
        message: 'Name of the new module',
        inputAttrs: {
          placeholder: 'e.g. My new copied module',
          value: 'Copy of ' + data.name
        },
        trapFocus: true,
        onConfirm: name => {
          copyModule(moduleInfo, name);
        }
      });
    } catch (err) {
      console.error(err);
    }
  };
  /**
   * @param {import('@/modules/builder/components/application/types/modulesList').IModuleItem} moduleInfo
   * @param {string} name
   */


  const copyModule = async (moduleInfo, name) => {
    try {
      const data = {
        name
      };

      if (moduleInfo?.moduleTypeId === 5) {
        data.parentId = route.params.moduleId;
      }

      await copyModuleService(route.params.appId, moduleInfo.id, data);
      buefy.toast.open('Module copied.');

      if (moduleInfo?.moduleTypeId === 5) {
        const {
          appId,
          moduleId
        } = route.params;
        await workflowStore.fetchWorkflows(appId, moduleId);
      }

      delegateAction(tableActions.value.DUPLICATE, moduleInfo);
    } catch (err) {
      console.error(err);
    }
  };
  /**
   * @param {import('@/modules/builder/components/application/types/modulesList').IModuleItem} moduleInfo 
   */


  const confirmDelete = async moduleInfo => {
    const {
      data: {
        data: {
          guarded_modules
        }
      }
    } = await fetchModuleByIdService(moduleInfo.application_id, moduleInfo.id);
    moduleToBeDeleted.value = {
      data: { ...moduleInfo
      },
      dependency: {
        guardedModules: guarded_modules?.idNames || []
      }
    };
    isDropdownVisible.value = false;
    isDeleteModuleModalVisible.value = true;
  };

  const deleteModule = async () => {
    try {
      await deleteModuleService(route.params.appId, moduleToBeDeleted.value.data.id);
      moduleToBeDeleted.value = {};
      isDeleteModuleModalVisible.value = false;
      buefy.toast.open('Module deleted!');
      delegateAction(tableActions.value.DELETE);
    } catch (err) {
      console.error(err);
    }
  };
  /**
   * @param {object} payload 
   * @param {'guard' | 'unguard'} guardStatus 
   */


  const updateGuardStatus = (payload, guardStatus) => {
    const moduleToBeUpdated = tableData.value.find(module => module.id === payload.moduleId);

    if (moduleToBeUpdated) {
      if (guardStatus === 'guard') {
        moduleToBeUpdated.guarded_module_id = payload.authModuleId;
      } else {
        moduleToBeUpdated.guarded_module_id = null;
      }
    }
  };

  return {
    tableActions,
    isSidebarVisible,
    isDeleteModuleModalVisible,
    sidebarInfo,
    moduleToBeDeleted,
    tableData,
    toggleOptionsDropdown,
    handleTableAction,
    deleteModule,
    updateGuardStatus
  };
};

__sfc_main.components = Object.assign({
  NoResults,
  ModuleOptionsDropdown,
  ModuleInfoSidebar,
  DeleteModuleModal
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';
@import '~@/style/components.scss';
@import '~@/style/utilities.scss';
</style>
