<template>
  <div class="position-relative">
    <small>Array Reference</small>
    <Editor
      v-model="formulaInfo.data.arrayReference"
      :input="true"
      :formula="true"
    />
    <small>Aggregated Result Reference</small>
    <Editor
      v-model="formulaInfo.data.resultReference"
      :input="true"
      :formula="true"
    />
    <small>Current Loop Item Reference (optional)</small>
    <Editor
      v-model="formulaInfo.data.currentItemReference"
      :input="true"
      :formula="true"
    />
    <small>Loop Index Reference (optional)</small>
    <Editor
      v-model="formulaInfo.data.indexReference"
      :input="true"
      :formula="false"
      class="mb-3"
    />
    <strong>Reduce items to:</strong>
    <br>
    <small>Type</small>
    <b-select
      :value="formulaInfo.data.reducedItem.type"
      size="is-small"
      expanded
      @input="updateReduceValueType"
    >
      <option
        v-for="(valueType, vIndex) in valueTypes"
        :key="'value-type-' + vIndex"
      >
        {{ valueType }}
      </option>
    </b-select>
    <small>Value</small>
    <JsonBuilder
      v-if="formulaInfo.data.reducedItem.type === 'Object'"
      v-model="formulaInfo.data.reducedItem.value"
    />
    <Editor
      v-else
      v-model="formulaInfo.data.reducedItem.value"
      :input="true"
      :formula="true"
    />
  </div>
</template>
  
<script >
// libs
import { defineAsyncComponent, onMounted, ref } from '@vue/composition-api';
import { debouncedWatch } from '@vueuse/core';
import lodash from 'lodash'; // components

import JsonBuilder from '@/modules/core/components/json-builder/JsonBuilder.vue';
const Editor = defineAsyncComponent(() => import('@/modules/core/components/wysiwyg/Editor'));
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const formulaInfo = ref({
    formula: 'REDUCE ARRAY',
    data: {
      arrayReference: '',
      resultReference: '',
      currentItemReference: '',
      indexReference: '',
      reducedItem: {
        type: '',
        value: ''
      }
    }
  });
  let avoidSavingFormula = false;
  onMounted(() => {
    if (Object.keys(props.value?.data || {}).length) {
      avoidSavingFormula = true;
      formulaInfo.value = lodash.cloneDeep(props.value);
    }
  });
  debouncedWatch(() => formulaInfo.value, () => {
    if (avoidSavingFormula) {
      avoidSavingFormula = false;
    } else {
      emit('input', formulaInfo.value);
    }
  }, {
    deep: true,
    debounce: 500
  }); // reduce logic

  const valueTypes = ref(['Number', 'String']);

  const updateReduceValueType = newType => {
    formulaInfo.value.data.reducedItem.type = newType;
    formulaInfo.value.data.reducedItem.value = newType === 'Object' ? [] : '';
  };

  return {
    formulaInfo,
    valueTypes,
    updateReduceValueType
  };
};

__sfc_main.components = Object.assign({
  Editor,
  JsonBuilder
}, __sfc_main.components);
export default __sfc_main;
</script>
